import { Action, createReducer, on } from '@ngrx/store';
import * as _ from 'lodash';
import { TrapState, trapStateDefault } from 'src/app/view/trap/trap.state';
import * as trapAction from './trap.actions';
import { Point } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

export const trapReducer = createReducer(
  trapStateDefault,
  /**
   * GET
   */
  on(trapAction.GET_TRAPS, (state) => ({
    ...state,
    loading: state.loading + 1,
  })),
  on(trapAction.GET_TRAPS_SUCCESS, (state, action) => ({
    ...state,
    traps: action.traps,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(trapAction.GET_TRAPS_FAIL, (state) => ({
    ...state,
    traps: [],
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(trapAction.GET_REGISTRATION_NUMBER, (state) => ({
    ...state,
    loading: state.loading + 1,
  })),
  on(trapAction.GET_REGISTRATION_NUMBER_SUCCESS, (state, action) => ({
    ...state,
    lastCount: action.lastCount,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(trapAction.GET_REGISTRATION_NUMBER_FAIL, (state) => ({
    ...state,
    lastCount: 0,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  on(trapAction.GET_FIELDS, (state) => ({
    ...state,
    loadingProgressBar: state.loadingProgressBar + 1,
  })),
  on(trapAction.GET_FIELDS_SUCCESS, (state, action) => ({
    ...state,
    fields: action.fields,
    loadingProgressBar:
      state.loadingProgressBar > 0 ? state.loadingProgressBar - 1 : (state.loadingProgressBar = 0),
  })),
  on(trapAction.GET_FIELDS_FAIL, (state) => ({
    ...state,
    fields: [],
    loadingProgressBar:
      state.loadingProgressBar > 0 ? state.loadingProgressBar - 1 : (state.loadingProgressBar = 0),
  })),

  on(trapAction.GET_GLEBES, (state) => ({
    ...state,
    loadingProgressBar: state.loadingProgressBar + 1,
  })),
  on(trapAction.GET_GLEBES_SUCCESS, (state, action) => ({
    ...state,
    glebes: action.glebes,
    loadingProgressBar:
      state.loadingProgressBar > 0 ? state.loadingProgressBar - 1 : (state.loadingProgressBar = 0),
  })),
  on(trapAction.GET_GLEBES_FAIL, (state) => ({
    ...state,
    glebes: [],
    loadingProgressBar:
      state.loadingProgressBar > 0 ? state.loadingProgressBar - 1 : (state.loadingProgressBar = 0),
  })),

  on(trapAction.GET_MANUFACTURED_TRAPS, (state) => ({
    ...state,
    loadingProgressBar: state.loadingProgressBar + 1,
  })),
  on(trapAction.GET_MANUFACTURED_TRAPS_SUCCESS, (state, action) => ({
    ...state,
    manufacturedTraps: action.manufacturedTraps,
    loadingProgressBar:
      state.loadingProgressBar > 0 ? state.loadingProgressBar - 1 : (state.loadingProgressBar = 0),
  })),
  on(trapAction.GET_MANUFACTURED_TRAPS_FAIL, (state) => ({
    ...state,
    devices: [],
    loadingProgressBar:
      state.loadingProgressBar > 0 ? state.loadingProgressBar - 1 : (state.loadingProgressBar = 0),
  })),

  /**
   * CREATE
   */
  on(trapAction.CREATE_TRAP, (state) => ({
    ...state,
    loadingModal: state.loadingModal + 1,
  })),
  on(trapAction.CREATE_TRAP_SUCCESS, (state) => ({
    ...state,
    closeModal: true,
  })),
  on(trapAction.CREATE_TRAP_FAIL, (state) => ({
    ...state,
    loadingModal: state.loadingModal > 0 ? state.loadingModal - 1 : (state.loadingModal = 0),
  })),

  /**
   * UPDATE
   */
  on(trapAction.UPDATE_TRAP_EDIT, (state, action) => {
    const trapEdit = _.cloneDeep(action.trap);

    if (!trapEdit.marker) {
      trapEdit.marker = {} as Point;
    }

    if (trapEdit.field) {
      trapEdit.glebeId = trapEdit.field.glebe.id;
      trapEdit.fieldId = trapEdit.field.id;
    }
    trapEdit.deviceId = trapEdit.device.id;

    return { ...state, trapEdit, setMarker: true };
  }),
  on(trapAction.UPDATE_TRAP, (state) => ({
    ...state,
  })),
  on(trapAction.UPDATE_TRAP_SUCCESS, (state) => ({
    ...state,
    closeModal: true,
  })),
  on(trapAction.UPDATE_TRAP_FAIL, (state) => ({
    ...state,
  })),

  /**
   * PATCH
   */
  on(trapAction.PATCH_TRAP, (state) => ({
    ...state,
  })),
  on(trapAction.PATCH_TRAP_SUCCESS, (state) => ({
    ...state,
    closeModal: true,
  })),
  on(trapAction.PATCH_TRAP_FAIL, (state) => ({
    ...state,
  })),

  /**
   * REMOVE
   */
  on(trapAction.REMOVE_TRAP, (state) => ({
    ...state,
    loadingRemove: state.loadingRemove + 1,
  })),
  on(trapAction.REMOVE_TRAP_SUCCESS, (state) => ({
    ...state,
    loadingRemove: state.loadingRemove > 0 ? state.loadingRemove - 1 : (state.loadingRemove = 0),
  })),
  on(trapAction.REMOVE_TRAP_FAIL, (state) => ({
    ...state,
    loadingRemove: state.loadingRemove > 0 ? state.loadingRemove - 1 : (state.loadingRemove = 0),
  })),

  /**
   * OTHER
   */
  on(trapAction.HIDE_MODAL, (state) => ({
    ...state,
    loadingModal: state.loadingModal > 0 ? state.loadingModal - 1 : (state.loadingModal = 0),
    closeModal: trapStateDefault.closeModal,
    setMarker: false,
    trapForm: trapStateDefault.trapForm,
    lastCount: trapStateDefault.lastCount,
  }))
);

export function reducer(state: TrapState | undefined, action: Action): TrapState {
  return trapReducer(state, action);
}
