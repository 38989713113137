// Device related constants
export const DEVICES = {
    DEVICES_TYPES: {
        TRAP_IAL: {
            id: 'TRAP_IAL',
            name: 'LD Autolimpante (IAL)',
            model: 'IAL',
            occurrences: [
                {
                    id: 'ARMIGERA',
                    pheromone: 'Helicoverpa armigera',
                    createReport: true,
                },
                {
                    id: 'FRUGIPERDA',
                    pheromone: 'Spodoptera frugiperda (lagarta-do-cartucho)',
                    createReport: true,
                },
                {
                    id: 'SACCHARALIS',
                    pheromone: 'Diatraea saccharalis',
                    createReport: true,
                },
                {
                    id: 'ERIDANIA',
                    pheromone: 'Spodoptera eridania',
                    createReport: false,
                },
                {
                    id: 'COSMIOIDES',
                    pheromone: 'Spodoptera cosmioides',
                    createReport: false,
                },
                {
                    id: 'VIRESCENS',
                    pheromone: 'Heliothis virescens',
                    createReport: false,
                },
                {
                    id: 'LAGARTA_FALSA_MEDIDEIRA',
                    pheromone: 'Chrysodeixis includens (falsa-medideira)',
                    createReport: false,
                },
                {
                    id: 'TRACA_TOMATEIRO',
                    pheromone: 'Tuta absoluta (traça-do-tomateiro)',
                    createReport: true,
                },
                {
                    id: 'BROCA_PEQUENA_FRUTO',
                    pheromone: 'Neoleucinodes elegantalis (broca-pequena-do-fruto)',
                    createReport: true,
                },
                {
                    id: 'CAPITATA',
                    pheromone: 'Ceratitis capitata',
                    createReport: true,
                },
                {
                    id: 'FRATERCULUS',
                    pheromone: 'Anastrepha fraterculus',
                    createReport: false,
                },
                {
                    id: 'OBLIQUA',
                    pheromone: 'Anastrepha obliqua',
                    createReport: false,
                },
            ],
        },
        TRAP_ISL: {
            id: 'TRAP_ISL',
            name: 'HF (ISL)',
            model: 'ISL',
            occurrences: [
                {
                    id: 'ARMIGERA',
                    pheromone: 'Helicoverpa armigera',
                    createReport: true,
                },
                {
                    id: 'FRUGIPERDA',
                    pheromone: 'Spodoptera frugiperda (lagarta-do-cartucho)',
                    createReport: true,
                },
                {
                    id: 'TRACA_TOMATEIRO',
                    pheromone: 'Tuta absoluta (traça-do-tomateiro)',
                    createReport: true,
                },
                {
                    id: 'BROCA_PEQUENA_FRUTO',
                    pheromone: 'Neoleucinodes elegantalis (broca-pequena-do-fruto)',
                    createReport: true,
                },
                {
                    id: 'CAPITATA',
                    pheromone: 'Ceratitis capitata',
                    createReport: true,
                },
                {
                    id: 'FRATERCULUS',
                    pheromone: 'Anastrepha fraterculus',
                    createReport: false,
                },
                {
                    id: 'OBLIQUA',
                    pheromone: 'Anastrepha obliqua',
                    createReport: false,
                },
            ],
        },
        TRAP_ITL: {
            id: 'TRAP_ITL',
            name: 'LD (ITL)',
            model: 'ITL',
            occurrences: [
                {
                    id: 'ARMIGERA',
                    pheromone: 'Helicoverpa armigera',
                    createReport: true,
                },
                {
                    id: 'FRUGIPERDA',
                    pheromone: 'Spodoptera frugiperda (lagarta-do-cartucho)',
                    createReport: true,
                },
                {
                    id: 'SACCHARALIS',
                    pheromone: 'Diatraea saccharalis',
                    createReport: true,
                },
                {
                    id: 'ERIDANIA',
                    pheromone: 'Spodoptera eridania',
                    createReport: false,
                },
                {
                    id: 'COSMIOIDES',
                    pheromone: 'Spodoptera cosmioides',
                    createReport: false,
                },
                {
                    id: 'VIRESCENS',
                    pheromone: 'Heliothis virescens',
                    createReport: false,
                },
                {
                    id: 'LAGARTA_FALSA_MEDIDEIRA',
                    pheromone: 'Chrysodeixis includens (falsa-medideira)',
                    createReport: false,
                },
                {
                    id: 'TRACA_TOMATEIRO',
                    pheromone: 'Tuta absoluta (traça-do-tomateiro)',
                    createReport: true,
                },
                {
                    id: 'BROCA_PEQUENA_FRUTO',
                    pheromone: 'Neoleucinodes elegantalis (broca-pequena-do-fruto)',
                    createReport: true,
                },
            ],
        },
        TRAP_IVL: {
            id: 'TRAP_IVL',
            name: 'VT (IVL)',
            model: 'IVL',
            occurrences: [
                {
                    id: 'CIGARRINHA_DO_MILHO_ADULTO',
                    pheromone: 'Dalbulus maidis (cigarrinha-do-milho)',
                    createReport: true,
                },
            ],
        },
        TRAP_PBL: {
            id: 'TRAP_PBL',
            name: 'Bicudo (PBL)',
            model: 'PBL',
            occurrences: [
                {
                    id: 'BICUDO',
                    pheromone: 'Bicudo',
                    createReport: true,
                },
            ],
        },
        TRAP_MMP: {
            id: 'TRAP_MMP',
            name: 'McPhail Manual (MMP)',
            model: 'MMP',
            occurrences: [
                {
                    id: 'CAPITATA_MACHO',
                    pheromone: 'Atrativo Alimentar',
                    createReport: false,
                },
                {
                    id: 'CAPITATA_FEMEA',
                    pheromone: 'Atrativo Alimentar',
                    createReport: false,
                },
                {
                    id: 'CAPITATA',
                    pheromone: 'Atrativo Alimentar',
                    createReport: true,
                },
                {
                    id: 'ANASTREPHA_MACHO',
                    pheromone: 'Atrativo Alimentar',
                    createReport: false,
                },
                {
                    id: 'ANASTREPHA_FEMEA',
                    pheromone: 'Atrativo Alimentar',
                    createReport: false,
                },
                {
                    id: 'ANASTREPHA',
                    pheromone: 'Atrativo Alimentar',
                    createReport: true,
                },
            ],
        },
        TRAP_MJP: {
            id: 'TRAP_MJP',
            name: 'Jackson Manual (MJP)',
            model: 'MJP',
            occurrences: [
                {
                    id: 'CAPITATA',
                    pheromone: 'Atrativo Alimentar',
                    createReport: true,
                },
            ],
        },
        TRAP_IFL: {
            id: 'TRAP_IFL',
            name: 'LD (IFL)',
            model: 'IFL',
            occurrences: [
                {
                    id: 'CIGARRINHA_DO_MILHO_ADULTO',
                    pheromone: 'Dalbulus maidis (cigarrinha-do-milho)',
                    createReport: true,
                },
            ],
        },
    },
    DEVICES_PROVIDER: {
        TTN: 'TTN',
        GLOBAL: 'GLOBAL',
        THIRDG: '3G',
        SIGFOX: 'SIGFOX',
    },
    AUTOCLEAN_STATUS: {
        SUCCESS_FULL_CHANGE: 'success - full change',
        SUCCESS_CLEAN_LINER: 'success - clean liner',
        SUCESS_NO_RUN: 'success - no run',
        FAIL_NO_MOVEMENT: 'fail - no movement',
        FAIL_PARTIAL_MOVEMENT: 'fail - partial movement',
        FAIL_UNKNOWN: 'fail - unknown',
        UNKNOWN: 'unknown - #1',
    },
};
