import { MonitoringType } from '../enums';
import { Occurrence } from '../interfaces';
import { CROPSEnum } from './crops';

const { CORN, SOY, COTTON, SUGAR, WHEAT, TOMATO, FRUITS, OTHERS } = CROPSEnum;

export const OCCURRENCE_CONSTANTS = {
    TYPES: {
        PLAGUE: 'PLAGUE',
        DISEASE: 'DISEASE',
        NATURAL_ENEMY: 'NATURAL_ENEMY',
        WEED: 'WEED',
        WEATHER: 'WEATHER',
        NUTRITION: 'NUTRITION',
        DAMAGE: 'DAMAGE',
    },
    INPUT_TYPES: {
        NUMBER: 'NUMBER',
        INTENSITY: 'INTENSITY',
        BOOLEAN: 'BOOLEAN',
        PERCENTAGE: 'PERCENTAGE',
    },
    UNIT_MEASURE: {
        UNITY: 'UND',
        INTENSITY: 'INTENSITY',
        PERCENTAGE: 'PERCENTAGE',
        OCCURRENCE_METERS: 'OCCURRENCE_METERS',
        BOOLEAN: 'BOOLEAN',
        NOT_DEFINED: 'NOT_DEFINED',
    },
    ACTIVE: [
        'FRUGIPERDA',
        'ARMIGERA',
        'SACCHARALIS',
        'CAPITATA',
        'ANASTREPHA',
        'TRACA_TOMATEIRO',
        'BROCA_PEQUENA_FRUTO',
        'CIGARRINHA_DO_MILHO_ADULTO',
    ],
} as { TYPES: any; INPUT_TYPES: any; UNIT_MEASURE: any; ACTIVE: any };

export const OCCURRENCES: Array<Occurrence> = [
    {
        id: 'FRUGIPERDA',
        name: 'Spodoptera frugiperda',
        scientificName: 'Spodoptera frugiperda',
        type: OCCURRENCE_CONSTANTS.TYPES.PLAGUE,
        cropsIds: [SOY, COTTON, CORN, WHEAT, SUGAR, TOMATO, OTHERS],
        monitoringType: MonitoringType.trap,
        active: true,
        defaultControlLevel: [
            {
                controlLevelType: 'TRAP',
                values: [
                    {
                        cropId: SOY,
                        cropName: 'Soja',
                        value: 20,
                        valueDamage: 22,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                    {
                        cropId: CORN,
                        cropName: 'Milho',
                        value: 20,
                        valueDamage: 22,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                    {
                        cropId: COTTON,
                        cropName: 'Algodão',
                        value: 20,
                        valueDamage: 22,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                    {
                        cropId: WHEAT,
                        cropName: 'Trigo',
                        value: 20,
                        valueDamage: 22,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                    {
                        cropId: SUGAR,
                        cropName: 'Cana de Açúcar',
                        value: 20,
                        valueDamage: 22,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                    {
                        cropId: TOMATO,
                        cropName: 'Tomate',
                        value: 20,
                        valueDamage: 22,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                    {
                        cropId: OTHERS,
                        cropName: 'Outros',
                        value: 10,
                        valueDamage: 11,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                ],
            },
        ],
    },
    {
        id: 'ARMIGERA',
        name: 'Helicoverpa armigera',
        scientificName: 'Helicoverpa armigera',
        type: OCCURRENCE_CONSTANTS.TYPES.PLAGUE,
        cropsIds: [SOY, COTTON, CORN, TOMATO, OTHERS],
        monitoringType: MonitoringType.trap,
        active: true,
        defaultControlLevel: [
            {
                controlLevelType: 'TRAP',
                values: [
                    {
                        cropId: SOY,
                        cropName: 'Soja',
                        value: 20,
                        valueDamage: 22,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                    {
                        cropId: CORN,
                        cropName: 'Milho',
                        value: 20,
                        valueDamage: 22,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                    {
                        cropId: COTTON,
                        cropName: 'Algodão',
                        value: 20,
                        valueDamage: 22,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                    {
                        cropId: TOMATO,
                        cropName: 'Tomate',
                        value: 20,
                        valueDamage: 22,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                    {
                        cropId: OTHERS,
                        cropName: 'Outros',
                        value: 10,
                        valueDamage: 11,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                ],
            },
        ],
    },
    {
        id: 'VIRESCENS',
        name: 'Heliothis virescens',
        scientificName: 'Heliothis virescens',
        type: OCCURRENCE_CONSTANTS.TYPES.PLAGUE,
        cropsIds: [SOY, COTTON, TOMATO, OTHERS],
        monitoringType: MonitoringType.trap,
        active: false,
        defaultControlLevel: [
            {
                controlLevelType: 'TRAP',
                values: [
                    {
                        cropId: SOY,
                        cropName: 'Soja',
                        value: 10,
                        valueDamage: 11,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                    {
                        cropId: COTTON,
                        cropName: 'Algodão',
                        value: 10,
                        valueDamage: 11,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                    {
                        cropId: TOMATO,
                        cropName: 'Tomate',
                        value: 10,
                        valueDamage: 11,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                    {
                        cropId: OTHERS,
                        cropName: 'Outros',
                        value: 10,
                        valueDamage: 11,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                ],
            },
        ],
    },
    {
        id: 'LAGARTA_ELASMO',
        name: 'Lagarta elasmo',
        scientificName: 'Elasmopalpus lignosellus',
        type: OCCURRENCE_CONSTANTS.TYPES.PLAGUE,
        cropsIds: [CORN, SOY, SUGAR],
        monitoringType: MonitoringType.trap,
        active: false,
        defaultControlLevel: [
            {
                controlLevelType: 'TRAP',
                values: [
                    {
                        cropId: CORN,
                        cropName: 'Milho',
                        value: 3,
                        valueDamage: 4,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                    {
                        cropId: SOY,
                        cropName: 'Soja',
                        value: 3,
                        valueDamage: 4,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                    {
                        cropId: SUGAR,
                        cropName: 'Cana de Açúcar',
                        value: 3,
                        valueDamage: 4,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                ],
            },
        ],
    },
    {
        id: 'ERIDANIA',
        name: 'Spodoptera eridania',
        scientificName: 'Spodoptera eridania',
        type: OCCURRENCE_CONSTANTS.TYPES.PLAGUE,
        cropsIds: [SOY, TOMATO, OTHERS],
        monitoringType: MonitoringType.trap,
        active: false,
        defaultControlLevel: [
            {
                controlLevelType: 'TRAP',
                values: [
                    {
                        cropId: SOY,
                        cropName: 'Soja',
                        value: 20,
                        valueDamage: 22,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                    {
                        cropId: TOMATO,
                        cropName: 'Tomate',
                        value: 20,
                        valueDamage: 22,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                    {
                        cropId: OTHERS,
                        cropName: 'Outros',
                        value: 10,
                        valueDamage: 11,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                ],
            },
        ],
    },
    {
        id: 'COSMIOIDES',
        name: 'Spodoptera cosmioides',
        scientificName: 'Spodoptera cosmioides',
        type: OCCURRENCE_CONSTANTS.TYPES.PLAGUE,
        cropsIds: [SOY, TOMATO, OTHERS],
        monitoringType: MonitoringType.trap,
        active: false,
        defaultControlLevel: [
            {
                controlLevelType: 'TRAP',
                values: [
                    {
                        cropId: SOY,
                        cropName: 'Soja',
                        value: 20,
                        valueDamage: 22,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                    {
                        cropId: TOMATO,
                        cropName: 'Tomate',
                        value: 20,
                        valueDamage: 22,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                    {
                        cropId: OTHERS,
                        cropName: 'Outros',
                        value: 10,
                        valueDamage: 11,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                ],
            },
        ],
    },
    {
        id: 'LAGARTA_FALSA_MEDIDEIRA',
        name: 'Lagarta falsa-medideira',
        scientificName: 'Chrysodeixis Includens',
        type: OCCURRENCE_CONSTANTS.TYPES.PLAGUE,
        cropsIds: [SOY, TOMATO, OTHERS],
        monitoringType: MonitoringType.trap,
        active: false,
        defaultControlLevel: [
            {
                controlLevelType: 'TRAP',
                values: [
                    {
                        cropId: SOY,
                        cropName: 'Soja',
                        value: 20,
                        valueDamage: 22,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                    {
                        cropId: TOMATO,
                        cropName: 'Tomate',
                        value: 20,
                        valueDamage: 22,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                    {
                        cropId: OTHERS,
                        cropName: 'Outros',
                        value: 10,
                        valueDamage: 11,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                ],
            },
        ],
    },
    {
        id: 'BICUDO',
        name: 'Bicudo',
        scientificName: 'Anthonomus grandis',
        type: OCCURRENCE_CONSTANTS.TYPES.PLAGUE,
        cropsIds: [COTTON, OTHERS],
        monitoringType: MonitoringType.trap,
        active: false,
        defaultControlLevel: [
            {
                controlLevelType: 'TRAP',
                values: [
                    {
                        cropId: COTTON,
                        cropName: 'Algodão',
                        value: 3,
                        valueDamage: 3.3,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                    {
                        cropId: OTHERS,
                        cropName: 'Outros',
                        value: 3,
                        valueDamage: 3.3,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                ],
            },
        ],
    },
    {
        id: 'SACCHARALIS',
        name: 'Diatraea saccharalis',
        scientificName: '',
        type: OCCURRENCE_CONSTANTS.TYPES.PLAGUE,
        cropsIds: [SUGAR, OTHERS],
        monitoringType: MonitoringType.trap,
        active: false,
        defaultControlLevel: [
            {
                controlLevelType: 'TRAP',
                values: [
                    {
                        cropId: SUGAR,
                        cropName: 'Cana de Açúcar',
                        value: 10,
                        valueDamage: 11,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                    {
                        cropId: OTHERS,
                        cropName: 'Outros',
                        value: 10,
                        valueDamage: 11,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                ],
            },
        ],
    },
    {
        id: 'CAPITATA',
        name: 'Ceratitis capitata',
        scientificName: 'Ceratitis capitata',
        type: OCCURRENCE_CONSTANTS.TYPES.PLAGUE,
        cropsIds: [FRUITS, OTHERS],
        monitoringType: MonitoringType.trap,
        active: true,
        defaultControlLevel: [
            {
                controlLevelType: 'TRAP',
                values: [
                    {
                        cropId: FRUITS,
                        cropName: 'Frutas',
                        value: 20,
                        valueDamage: 22,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                    {
                        cropId: OTHERS,
                        cropName: 'Outros',
                        value: 10,
                        valueDamage: 11,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                ],
            },
        ],
    },
    {
        id: 'CAPITATA_MACHO',
        name: 'Ceratitis capitata (macho)',
        scientificName: 'Ceratitis capitata (macho)',
        type: OCCURRENCE_CONSTANTS.TYPES.PLAGUE,
        cropsIds: [FRUITS, OTHERS],
        monitoringType: MonitoringType.trap,
        active: false,
        defaultControlLevel: [
            {
                controlLevelType: 'TRAP',
                values: [
                    {
                        cropId: FRUITS,
                        cropName: 'Frutas',
                        value: 20,
                        valueDamage: 22,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                    {
                        cropId: OTHERS,
                        cropName: 'Outros',
                        value: 10,
                        valueDamage: 11,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                ],
            },
        ],
    },
    {
        id: 'CAPITATA_FEMEA',
        name: 'Ceratitis capitata (femea)',
        scientificName: 'Ceratitis capitata (femea)',
        type: OCCURRENCE_CONSTANTS.TYPES.PLAGUE,
        cropsIds: [FRUITS, OTHERS],
        monitoringType: MonitoringType.trap,
        active: false,
        defaultControlLevel: [
            {
                controlLevelType: 'TRAP',
                values: [
                    {
                        cropId: FRUITS,
                        cropName: 'Frutas',
                        value: 20,
                        valueDamage: 22,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                    {
                        cropId: OTHERS,
                        cropName: 'Outros',
                        value: 10,
                        valueDamage: 11,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                ],
            },
        ],
    },
    {
        id: 'FRATERCULUS',
        name: 'Anastrepha fraterculus',
        scientificName: 'Anastrepha fraterculus',
        type: OCCURRENCE_CONSTANTS.TYPES.PLAGUE,
        cropsIds: [FRUITS, OTHERS],
        monitoringType: MonitoringType.trap,
        active: false,
        defaultControlLevel: [
            {
                controlLevelType: 'TRAP',
                values: [
                    {
                        cropId: FRUITS,
                        cropName: 'Frutas',
                        value: 20,
                        valueDamage: 22,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                    {
                        cropId: OTHERS,
                        cropName: 'Outros',
                        value: 10,
                        valueDamage: 11,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                ],
            },
        ],
    },
    {
        id: 'ANASTREPHA_MACHO',
        name: 'Anastrepha (macho)',
        scientificName: 'Anastrepha (macho)',
        type: OCCURRENCE_CONSTANTS.TYPES.PLAGUE,
        cropsIds: [FRUITS, OTHERS],
        monitoringType: MonitoringType.trap,
        active: false,
        defaultControlLevel: [
            {
                controlLevelType: 'TRAP',
                values: [
                    {
                        cropId: FRUITS,
                        cropName: 'Frutas',
                        value: 20,
                        valueDamage: 22,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                    {
                        cropId: OTHERS,
                        cropName: 'Outros',
                        value: 10,
                        valueDamage: 11,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                ],
            },
        ],
    },
    {
        id: 'ANASTREPHA_FEMEA',
        name: 'Anastrepha (femea)',
        scientificName: 'Anastrepha (femea)',
        type: OCCURRENCE_CONSTANTS.TYPES.PLAGUE,
        cropsIds: [FRUITS, OTHERS],
        monitoringType: MonitoringType.trap,
        active: false,
        defaultControlLevel: [
            {
                controlLevelType: 'TRAP',
                values: [
                    {
                        cropId: FRUITS,
                        cropName: 'Frutas',
                        value: 20,
                        valueDamage: 22,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                    {
                        cropId: OTHERS,
                        cropName: 'Outros',
                        value: 10,
                        valueDamage: 11,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                ],
            },
        ],
    },
    {
        id: 'ANASTREPHA',
        name: 'Anastrepha',
        scientificName: 'Anastrepha',
        type: OCCURRENCE_CONSTANTS.TYPES.PLAGUE,
        cropsIds: [FRUITS, OTHERS],
        monitoringType: MonitoringType.trap,
        active: true,
        defaultControlLevel: [
            {
                controlLevelType: 'TRAP',
                values: [
                    {
                        cropId: FRUITS,
                        cropName: 'Frutas',
                        value: 20,
                        valueDamage: 22,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                    {
                        cropId: OTHERS,
                        cropName: 'Outros',
                        value: 10,
                        valueDamage: 11,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                ],
            },
        ],
    },
    {
        id: 'OBLIQUA',
        name: 'Anastrepha obliqua',
        scientificName: 'Anastrepha obliqua',
        type: OCCURRENCE_CONSTANTS.TYPES.PLAGUE,
        cropsIds: [FRUITS, OTHERS],
        monitoringType: MonitoringType.trap,
        active: false,
        defaultControlLevel: [
            {
                controlLevelType: 'TRAP',
                values: [
                    {
                        cropId: FRUITS,
                        cropName: 'Frutas',
                        value: 20,
                        valueDamage: 22,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                    {
                        cropId: OTHERS,
                        cropName: 'Outros',
                        value: 10,
                        valueDamage: 11,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                ],
            },
        ],
    },
    {
        id: 'CIGARRINHA_DO_MILHO_ADULTO',
        name: 'Cigarrinha-do-milho',
        scientificName: 'Dalbulus maidis',
        type: OCCURRENCE_CONSTANTS.TYPES.PLAGUE,
        cropsIds: [CORN, OTHERS],
        monitoringType: MonitoringType.trap,
        active: true,
        defaultControlLevel: [
            {
                controlLevelType: 'TRAP',
                values: [
                    {
                        cropId: CORN,
                        cropName: 'Milho',
                        value: 1,
                        valueDamage: 1,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                    {
                        cropId: OTHERS,
                        cropName: 'Outros',
                        value: 1,
                        valueDamage: 1,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                ],
            },
        ],
    },
    {
        id: 'BROCA_PEQUENA_FRUTO',
        name: 'Broca-pequena-do-fruto',
        scientificName: 'Neoleucinoides elegantalis',
        type: OCCURRENCE_CONSTANTS.TYPES.PLAGUE,
        cropsIds: [TOMATO, OTHERS],
        monitoringType: MonitoringType.trap,
        active: true,
        defaultControlLevel: [
            {
                controlLevelType: 'TRAP',
                values: [
                    {
                        cropId: TOMATO,
                        cropName: 'Tomate',
                        value: 10,
                        valueDamage: 11,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                    {
                        cropId: OTHERS,
                        cropName: 'Outros',
                        value: 10,
                        valueDamage: 11,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                ],
            },
        ],
    },
    {
        id: 'TRACA_TOMATEIRO',
        name: 'Traça-do-tomateiro',
        scientificName: 'Tuta absoluta',
        type: OCCURRENCE_CONSTANTS.TYPES.PLAGUE,
        cropsIds: [TOMATO, OTHERS],
        monitoringType: MonitoringType.trap,
        active: true,
        defaultControlLevel: [
            {
                controlLevelType: 'TRAP',
                values: [
                    {
                        cropId: TOMATO,
                        cropName: 'Tomate',
                        value: 10,
                        valueDamage: 11,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                    {
                        cropId: OTHERS,
                        cropName: 'Outros',
                        value: 10,
                        valueDamage: 11,
                        unit: OCCURRENCE_CONSTANTS.UNIT_MEASURE.UNITY,
                    },
                ],
            },
        ],
    },
];
