<!-- Loading -->
<app-loading [isOpen]="state.loadingModal || state.loading"></app-loading>

<mat-toolbar class="material-dialog-toolbar tm-dialog-toolbar-color" color="primary">
  <button mat-icon-button aria-label="Close Modal" (click)="onCloseModal()" tabindex="-1">
    <i class="las la-times"></i>
  </button>
  <span class="title">{{ trans.button.defineGlebe | label }}</span>
</mat-toolbar>

<mat-dialog-content class="material-dialog-content">
  <form class="custom-form-field" [formGroup]="firstFormGroup">
    <div fxLayout="column" fxLayout.gt-xs="row wrap" fxLayout.gt-md="row wrap">
      <!-- glebe -->
      <mat-form-field fxFlex="100%" [fxShow]="!isCreateGlebe">
        <mat-label>{{ trans.label.glebe | label }}</mat-label>
        <mat-select
          #materialSelect
          id="glebe"
          [compareWith]="compareSelectedObject"
          cdkFocusInitial
          [appDisableControl]="isCreateGlebe"
          formControlName="selectedGlebe"
          required
        >
          <mat-option
            class="material-select-option-message"
            *ngIf="state.glebes.length <= 0"
            [disabled]="true"
          >
            {{ trans.text.noRegisteredGlebes | label }}</mat-option
          >
          <mat-option
            *ngFor="let glebe of state.glebes"
            [value]="glebe"
            [disabled]="glebe.harvested"
          >
            {{ glebe.name }}
            <span *ngIf="glebe.alias && glebe.alias !== ''">( {{ glebe.alias }} )</span>
            <span
              style="float: right"
              matTooltipClass="material-tooltip-class"
              [matTooltip]="trans.text.harvestedDate | translate"
              [matTooltipPosition]="'left'"
              *ngIf="glebe.harvested"
            >
              {{ moment(glebe.finalHarvestDate.toDate()).format(trans.text.date | translate) }}
            </span>
          </mat-option>
        </mat-select>
        <mat-error>
          {{ getInputMessageError('glebe', firstFormGroup.get('selectedGlebe')?.errors) | label }}
        </mat-error>
      </mat-form-field>

      <div
        fxLayout="column"
        fxLayout.gt-xs="row wrap"
        fxLayout.gt-md="row wrap"
        formGroupName="glebe"
        [fxShow]="isCreateGlebe"
      >
        <!-- name -->
        <mat-form-field fxFlex="100%">
          <input
            matInput
            type="text"
            id="name"
            formControlName="name"
            [appDisableControl]="true"
            required
          />
          <mat-error>
            {{ getInputMessageError('name', firstFormGroup.get('name')?.errors) | translate }}
          </mat-error>
        </mat-form-field>

        <!-- alias -->
        <mat-form-field fxFlex="100%">
          <input
            matInput
            type="text"
            id="glebe"
            formControlName="alias"
            [appDisableControl]="!isCreateGlebe"
            placeholder="{{ trans.placeholder.glebeAlias | label }}"
            minlength="2"
            maxlength="64"
            cdkFocusInitial
            [pattern]="regex.text"
          />
          <mat-error>
            {{ getInputMessageError('alias', firstFormGroup.get('alias')?.errors) | translate }}
          </mat-error>
        </mat-form-field>

        <!-- select crop -->
        <mat-form-field fxFlex="100%">
          <mat-label>{{ trans.label.crop | translate }}</mat-label>
          <mat-select
            #materialSelect
            cdkFocusInitial
            id="cropId"
            formControlName="cropId"
            [appDisableControl]="!isCreateGlebe"
            (selectionChange)="onSelectionChangeCrop($event.value)"
            required
          >
            <mat-option
              class="material-select-option-message"
              *ngIf="crops.length <= 0"
              [disabled]="true"
            >
              {{ trans.text.noRegisteredCrops | translate }}</mat-option
            >
            <mat-option *ngFor="let crop of crops" [value]="crop.id">
              {{ crop.name }}
            </mat-option>
          </mat-select>
          <mat-error>
            {{
              getInputMessageError('crop', firstFormGroup.get('glebe')?.get('cropId')?.errors)
                | translate
            }}
          </mat-error>
        </mat-form-field>

        <!-- varieties -->
        <mat-form-field fxFlex="100%">
          <input
            matInput
            type="text"
            id="varieties"
            formControlName="varieties"
            [appDisableControl]="!isCreateGlebe"
            placeholder="{{ trans.placeholder.cropVarieties | translate }}"
            [pattern]="regex.text"
            cdkFocusInitial
          />
          <mat-error>
            {{
              getInputMessageError(
                'varieties',
                firstFormGroup.get('glebe')?.get('varieties')?.errors
              ) | translate
            }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="material-dialog-actions" align="space-between">
  <button mat-button (click)="onCloseModal()">{{ trans.button.close | translate }}</button>
  <div>
    <button
      mat-button
      color="primary"
      *ngIf="isCreateGlebe"
      (click)="isCreateGlebe = !isCreateGlebe"
    >
      {{ trans.button.back | translate }}
    </button>
    <button
      mat-button
      color="primary"
      *ngIf="!isCreateGlebe"
      (click)="isCreateGlebe = !isCreateGlebe"
    >
      {{ trans.button.createGlebe | label }}
    </button>
    <button mat-button color="primary" (click)="onFormClick()">
      {{ trans.button.save | translate }}
    </button>
  </div>
</mat-dialog-actions>
