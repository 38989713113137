import { createAction, props } from '@ngrx/store';
import { EvolutionChart, Farm, Field } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { TrapReport } from '@tarvos-ag/tarvos-firestore-models/src/interfaces/TrapReport';
import { ReportData } from '@tarvos-ag/tarvos-firestore-models/src/interfaces/ReportData';
// import { MadChart } from '@tarvos-ag/tarvos-firestore-models/src/interfaces/Mad';
// import { AccumulatedChart } from '@tarvos-ag/tarvos-firestore-models/src/interfaces/AccumulatedChart';

/**
 * Get
 */

export const GET_REPORT_FRUIT = createAction(
  '[REPORT PAGE] GET_REPORT_FRUIT',
  props<{ token: string; ux: string }>()
);
export const GET_REPORT_FRUIT_SUCCESS = createAction(
  '[REPORT PAGE] GET_REPORT_FRUIT_SUCCESS',
  props<{
    customerId: string;
    data: ReportData;
    farm: Farm;
    farmId: string;
    fields: Array<Field>;
    harvestId: string;
    occurrences: Array<any>;
    reportFruit: TrapReport;
    showModal: boolean;
  }>()
);
export const GET_REPORT_FRUIT_FAIL = createAction(
  '[REPORT PAGE] GET_REPORT_FRUIT_FAIL',
  props<{ error: any }>()
);

export const GET_EVOLUTION_CHART = createAction(
  '[REPORT PAGE] GET_EVOLUTION_CHART',
  props<{
    startDate: Date;
    endDate: Date;
    customerId: string;
    farmId: string;
    harvestId: string;
    fieldIds: Array<string>;
    occurrenceId: string;
  }>()
);
export const GET_EVOLUTION_CHART_SUCCESS = createAction(
  '[REPORT PAGE] GET_EVOLUTION_CHART_SUCCESS',
  props<{ evolutionChart: EvolutionChart }>()
);
export const GET_EVOLUTION_CHART_FAIL = createAction(
  '[REPORT PAGE] GET_EVOLUTION_CHART_FAIL',
  props<{ error: any }>()
);

/*
export const GET_WEATHER_CHART = createAction('[REPORT PAGE] GET_WEATHER_CHART');
export const GET_WEATHER_CHART_SUCCESS = createAction(
  '[REPORT PAGE] GET_WEATHER_CHART_SUCCESS',
  props<{ weatherChart: any }>()
);
export const GET_WEATHER_CHART_FAIL = createAction(
  '[REPORT PAGE] GET_WEATHER_CHART_FAIL',
  props<{ error: any }>()
);
*/

// export const GET_ACCUMULATED_CHART = createAction(
//   '[REPORT PAGE] GET_ACCUMULATED_CHART',
//   props<{
//     customerId: string;
//     farmId: string;
//     harvestId: string;
//     fieldId: string;
//     occurrenceId: string;
//     days: number;
//     endDate: Date;
//   }>()
// );
// export const GET_ACCUMULATED_CHART_SUCCESS = createAction(
//   '[REPORT PAGE] GET_ACCUMULATED_CHART_SUCCESS',
//   props<{ reportAccumulated: AccumulatedChart }>()
// );
// export const GET_ACCUMULATED_CHART_FAIL = createAction(
//   '[REPORT PAGE] GET_ACCUMULATED_CHART_FAIL',
//   props<{ error: any }>()
// );

// export const GET_MAD_CHART = createAction(
//   '[REPORT PAGE] GET_MAD_CHART',
//   props<{
//     startDate: Date;
//     endDate: Date;
//     customerId: string;
//     farmId: string;
//     harvestId: string;
//     glebeName: string;
//   }>()
// );
// export const GET_MAD_CHART_SUCCESS = createAction(
//   '[REPORT PAGE] GET_MAD_CHART_SUCCESS',
//   props<{ madChart: Array<MadChart> }>()
// );
// export const GET_MAD_CHART_FAIL = createAction(
//   '[REPORT PAGE] GET_MAD_CHART_FAIL',
//   props<{ error: any }>()
// );

/**
 * Update
 */
export const INSTRUCTION_READ = createAction(
  '[REPORT PAGE] INSTRUCTION_READ',
  props<{ limitDate: 'string' }>()
);

/**
 * Others
 */

export const DESTROY_COMPONENT = createAction('[REPORT PAGE] DESTROY_COMPONENT');
