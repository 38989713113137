import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ApplicationService } from '../../../services/application.service';
import { GenericFirestoreService } from '../../../services/generic-firestore.service';
import { GenericHttpService } from '../../../services/generic-http.service';
import { TranslateTypes } from '../../../services/translation.service';
import { AngularFirestore, CollectionReference } from '@angular/fire/compat/firestore';
import { MyToastrService } from '../../../services/toastr.service';
import { GenericReportFilesService } from '../../../services/generic-report-files.service';
import {
  Field,
  TrapReport,
  WebUser,
  Glebe,
  Harvest,
  Trap,
} from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { TrapReportXls } from '@tarvos-ag/tarvos-firestore-models/src/interfaces/TrapReport';

import * as trapReportFormAction from './trap-report-form.actions';
import { TrapReportPath } from 'src/app/constants/trapReportPath';
import { CalculateService } from 'src/app/services/calculate.service';
import { StatusReportType } from '@tarvos-ag/tarvos-firestore-models/src/enums';
import { trapReportColumns } from './trap-report-form.state';

@Injectable()
export class TrapReportFormEffects {
  constructor(
    private actions$: Actions,
    private genericFirestoreService: GenericFirestoreService,
    private genericHttpService: GenericHttpService,
    private applicationService: ApplicationService,
    private toastrService: MyToastrService,
    private calculateService: CalculateService,
    private genericReportFilesService: GenericReportFilesService,
    private db: AngularFirestore,
    public trans: TranslateTypes
  ) {}

  /**
   * Este método chama um serviço obter o ultimo relatório de armadilha cadastrada e retornar seu número de registro
   */
  public getlastCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(trapReportFormAction.GET_HIGHEST_NUMBER_REGISTERED),
      switchMap(() => {
        let lastCount;
        if (!this.applicationService.getFarm()?.collectionSettings?.report?.lastCount) {
          const interactiveList = this.applicationService
            .getHarvestList()
            .map((harvest: Harvest) => {
              return {
                ref: `${this.applicationService.getFarmRefDatabase()}/harvests/${harvest.id}/${
                  TrapReportPath.INTERACTIVE
                }`,
                config: {
                  queryFn: (ref: CollectionReference) =>
                    ref.orderBy('registrationNumber', 'desc').limit(1),
                },
              };
            });
          const interactiveFruitList = this.applicationService
            .getHarvestList()
            .map((harvest: Harvest) => {
              return {
                ref: `${this.applicationService.getFarmRefDatabase()}/harvests/${harvest.id}/${
                  TrapReportPath.INTERACTIVEFRUIT
                }`,
                config: {
                  queryFn: (ref: CollectionReference) =>
                    ref.orderBy('registrationNumber', 'desc').limit(1),
                },
              };
            });
          const geoPdfList = this.applicationService.getHarvestList().map((harvest: Harvest) => {
            return {
              ref: `${this.applicationService.getFarmRefDatabase()}/harvests/${harvest.id}/${
                TrapReportPath.GEOREFERENCED
              }`,
              config: {
                queryFn: (ref: CollectionReference) =>
                  ref.orderBy('registrationNumber', 'desc').limit(1),
              },
            };
          });
          const madPdfList = this.applicationService.getHarvestList().map((harvest: Harvest) => {
            return {
              ref: `${this.applicationService.getFarmRefDatabase()}/harvests/${harvest.id}/${
                TrapReportPath.MAD
              }`,
              config: {
                queryFn: (ref: CollectionReference) =>
                  ref.orderBy('registrationNumber', 'desc').limit(1),
              },
            };
          });
          const xlsList = this.applicationService.getHarvestList().map((harvest: Harvest) => {
            return {
              ref: `${this.applicationService.getFarmRefDatabase()}/harvests/${harvest.id}/${
                TrapReportPath.XLS
              }`,
              config: {
                queryFn: (ref: CollectionReference) =>
                  ref.orderBy('registrationNumber', 'desc').limit(1),
              },
            };
          });

          const combineList = [
            ...interactiveList,
            ...interactiveFruitList,
            ...xlsList,
            ...geoPdfList,
            ...madPdfList,
          ];
          return this.genericFirestoreService
            .getCombine<TrapReport>(combineList, 'registrationNumber', 'desc')
            .pipe(
              map(
                (trapReports: Array<TrapReport>) => {
                  const registrationNumberMap = trapReports
                    .filter((trap) => trap.registrationNumber)
                    .map((trap: TrapReport) => trap.registrationNumber);
                  lastCount = this.calculateService.calculateLastCount(registrationNumberMap);
                  return trapReportFormAction.GET_HIGHEST_NUMBER_REGISTERED_SUCCESS({
                    lastCount,
                  });
                },
                catchError((error) =>
                  of(trapReportFormAction.GET_HIGHEST_NUMBER_REGISTERED_FAIL(error))
                )
              )
            );
        } else {
          lastCount = this.applicationService.getFarm()?.collectionSettings?.report?.lastCount
            ? this.applicationService.getFarm()!.collectionSettings!.report!.lastCount
            : 0;
          return of(
            trapReportFormAction.GET_HIGHEST_NUMBER_REGISTERED_SUCCESS({
              lastCount,
            })
          );
        }
      })
    );
  });

  /**
   * Este método chama um serviço para buscar um relatório.
   */
  // public getTrapReports$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(trapReportFormAction.GET_TRAP_REPORTS),
  //     switchMap(() =>
  //       this.genericFirestoreService
  //         .getCombine<TrapReport>([
  //           {
  //             ref: `${this.applicationService.getHarvestRefDatabase()}/reportXlsTraps`,
  //           },
  //           {
  //             ref: `${this.applicationService.getHarvestRefDatabase()}/reportPdfTraps`,
  //           },
  //           {
  //             ref: `${this.applicationService.getHarvestRefDatabase()}/geoPDFReport`,
  //           },
  //           {
  //             ref: `${this.applicationService.getHarvestRefDatabase()}/madReport`,
  //           },
  //         ])
  //         .pipe(
  //           map(
  //             (trapReports: Array<TrapReport>) =>
  //               trapReportFormAction.GET_TRAP_REPORTS_SUCCESS({ trapReports }),
  //             catchError((error) => of(trapReportFormAction.GET_TRAP_REPORTS_FAIL(error)))
  //           )
  //         )
  //     )
  //   );
  // });

  /**
   * Este método chama um serviço para listar usuários.
   */
  public getUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(trapReportFormAction.GET_USERS),
      switchMap(() =>
        this.genericFirestoreService
          .getCombine<WebUser>(
            [
              {
                ref: `${this.applicationService.getUserRefDatabase()}`,
                config: {
                  queryFn: (ref: any) =>
                    ref.where('farmIds', 'array-contains', this.applicationService.getFarmId()),
                },
              },
            ],
            'name',
            'asc'
          )
          .pipe(
            map((users: Array<WebUser>) => trapReportFormAction.GET_USERS_SUCCESS({ users })),
            catchError((error) => of(trapReportFormAction.GET_USERS_FAIL(error)))
          )
      )
    );
  });

  /**
   * Este método chama um serviço para listar talhões.
   */
  public getFields$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(trapReportFormAction.GET_FIELDS),
      switchMap(() =>
        this.genericFirestoreService
          .getAll<Field>(`${this.applicationService.getHarvestRefDatabase()}/fields`)
          .pipe(
            map((fields: Array<Field>) => trapReportFormAction.GET_FIELDS_SUCCESS({ fields })),
            catchError((error) => of(trapReportFormAction.GET_FIELDS_FAIL(error)))
          )
      )
    );
  });

  /**
   * Este método chama um serviço para listar glebas.
   */
  public getGlebes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(trapReportFormAction.GET_GLEBES),
      switchMap(() =>
        this.genericFirestoreService
          .getAll<Glebe>(`${this.applicationService.getHarvestRefDatabase()}/glebes`, {
            queryFn: (ref: CollectionReference) => ref.orderBy('name', 'asc'),
          })
          .pipe(
            map((glebes: Array<Glebe>) => trapReportFormAction.GET_GLEBES_SUCCESS({ glebes })),
            catchError((error) => of(trapReportFormAction.GET_GLEBES_FAIL(error)))
          )
      )
    );
  });

  /**
   * Este método chama um serviço para armadilhas.
   */
  public getTraps$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(trapReportFormAction.GET_TRAPS),
      switchMap(() =>
        this.genericFirestoreService
          .getAll<Trap>(`${this.applicationService.getFarmRefDatabase()}/traps`, {
            queryFn: (ref: CollectionReference) =>
              ref.where('harvestId', '==', this.applicationService.getHarvestId()),
          })
          .pipe(
            map((traps: Array<Trap>) => trapReportFormAction.GET_TRAPS_SUCCESS({ traps })),
            catchError((error) => of(trapReportFormAction.GET_TRAPS_FAIL(error)))
          )
      )
    );
  });

  /**
   * Este método chama um serviço para cadastrar solicitação de relatórios.
   */
  public createTrapReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(trapReportFormAction.CREATE_TRAP_REPORT),
      switchMap((action) => {
        const parameters = {
          type: 'traps',
          customerId: this.applicationService.getCustomerId(),
          farmId: this.applicationService.getFarmId(),
          harvestId: this.applicationService.getHarvestId(),
        };

        const data = {
          ...action.trapReport,
          id: action.trapReport.id ? action.trapReport.id : this.db.createId(),
        };
        return this.genericHttpService
          .create<{ data: TrapReport }>('generateReports', { ...parameters, data })
          .toPromise()
          .then((result: any) => {
            if (result && result.code === 'report/completed') {
              return trapReportFormAction.CREATE_TRAP_REPORT_SUCCESS({
                response: { status: StatusReportType.completed, id: result.id },
              });
            } else {
              return trapReportFormAction.CREATE_TRAP_REPORT_SUCCESS({
                response: { status: StatusReportType.in_progress, id: result.id },
              });
            }
          })
          .catch((error) => {
            this.toastrService.error(
              `${
                error.code !== undefined
                  ? error.code
                  : error.error.code !== undefined
                  ? error.error.code
                  : error.error.internalCode
              }`
            );
            return trapReportFormAction.CREATE_TRAP_REPORT_FAIL(error);
          });
      })
    )
  );

  /**
   * Este método chama um serviço para exportar um relatório em xls
   */
  public createReportXls$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(trapReportFormAction.CREATE_TRAP_REPORT_XLS),
      switchMap(async (action) => {
        const harvestRef = this.applicationService.getHarvestRefDatabase();
        const trapReports = await this.genericFirestoreService
          .getById<TrapReport>(`${harvestRef}/reportXlsTraps/${action.id}`)
          .first()
          .toPromise();
        let trapReportXls = trapReports.data;

        trapReportXls = trapReportXls?.map((trapReportData: TrapReportXls) => {
          const trapReportOrder = {
            trap: trapReportData.trap,
            glebe: trapReportData.glebe,
            field: trapReportData.field,
            crop: trapReportData.crop,
            pheromone: trapReportData.pheromone,
            occurrence: trapReportData.occurrence,
            count: trapReportData.count,
            date: trapReportData.date,
          } as unknown as TrapReportXls;

          return trapReportOrder;
        });

        return this.genericReportFilesService
          .generateExcelFile(trapReportXls, trapReportColumns)
          .then(() => trapReportFormAction.CREATE_TRAP_REPORT_XLS_SUCCESS())
          .catch((error) => trapReportFormAction.CREATE_TRAP_REPORT_XLS_FAIL(error));
      })
    );
  });

    /**
     * Este método chama um serviço para listar ocorrências.
     */
    public getOccurrences$ = createEffect(() =>
      this.actions$.pipe(
        ofType(trapReportFormAction.GET_OCCURRENCES),
        switchMap(() =>
          this.genericFirestoreService
            .getAll<any>(`${this.applicationService.getHarvestRefDatabase()}/filters`)
            .pipe(
              map((occurrences: Array<any>) => trapReportFormAction.GET_OCCURRENCES_SUCCESS({ occurrences })),
              catchError((error) => of(trapReportFormAction.GET_OCCURRENCES_FAIL(error)))
            )
        )
      )
    );

  /**
   * Este método chama um serviço para atualizar relatório.
   */
  public updateTrapReport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(trapReportFormAction.UPDATE_TRAP_REPORT),
      switchMap((action) => {
        const collection = TrapReportPath[action.trapReport.reportFormat];
        return this.genericFirestoreService
          .update<TrapReport>(`${this.applicationService.getHarvestRefDatabase()}/${collection}`, {
            ...action.trapReport,
          })
          .then(() => {
            this.toastrService.success(this.trans.services.update.trapReportSuccess, {
              '%0': action.trapReport.name,
            });
            return trapReportFormAction.UPDATE_TRAP_REPORT_SUCCESS();
          })
          .catch((error) => {
            this.toastrService.error(
              `${
                error.code !== undefined
                  ? error.code
                  : error.error.code !== undefined
                  ? error.error.code
                  : error.error.internalCode
              }`
            );
            return trapReportFormAction.UPDATE_TRAP_REPORT_FAIL(error);
          });
      })
    );
  });
}
