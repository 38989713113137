import { Crop } from '../interfaces';

const CAFE: Crop = {
    id: 'CAFE',
    name: 'Café',
};

const MILHO: Crop = {
    id: 'MILHO',
    name: 'Milho',
};

const SOJA: Crop = {
    id: 'SOJA',
    name: 'Soja',
};

const ALGODAO: Crop = {
    id: 'ALGODAO',
    name: 'Algodão',
};

const CANA_DE_ACUCAR: Crop = {
    id: 'CANA_DE_ACUCAR',
    name: 'Cana de Açúcar',
};

const TRIGO: Crop = {
    id: 'TRIGO',
    name: 'Trigo',
};

const TOMATE: Crop = {
    id: 'TOMATE',
    name: 'Tomate',
};

const FRUTAS: Crop = {
    id: 'FRUTAS',
    name: 'Frutas',
};

const OUTROS: Crop = {
    id: 'OUTROS',
    name: 'Outros',
};

export const CROPS: Array<Crop> = [
    CAFE,
    MILHO,
    SOJA,
    ALGODAO,
    CANA_DE_ACUCAR,
    TRIGO,
    TOMATE,
    FRUTAS,
    OUTROS,
];

export enum CROPSEnum {
    COFFEE = 'CAFE',
    CORN = 'MILHO',
    SOY = 'SOJA',
    COTTON = 'ALGODAO',
    SUGAR = 'CANA_DE_ACUCAR',
    WHEAT = 'TRIGO',
    TOMATO = 'TOMATE',
    FRUITS = 'FRUTAS',
    OTHERS = 'OUTROS',
}
