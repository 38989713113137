import { FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from '../../utils/custom-validators/custom-validators';
import { Glebe } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

export const defineFieldFormStateDefault = {
  glebes: [],
  loading: 0,
  loadingModal: 0,
  closeModal: false,
  lastCount: 0,
  createGlebeSuccess: null,
} as DefineFieldFormState;

export const formGroup = {
  id: [null],
  name: [null, [Validators.required, Validators.minLength(2)]],
  alias: [
    null,
    [Validators.minLength(2), Validators.maxLength(30), CustomValidators.whitespaceValidator],
  ],
  cropId: [null, [Validators.required]],
  cropName: [null],
  varieties: [
    null,
    [Validators.minLength(2), Validators.maxLength(30), CustomValidators.whitespaceValidator],
  ],
  harvested: [false],
};

export const firstFormGroup = {
  selectedGlebe: [null, [Validators.required]],
  glebe: new FormBuilder().group(formGroup),
} as any;

export interface DefineFieldFormState {
  glebes: Array<Glebe>;
  loading: number;
  loadingModal: number;
  closeModal: boolean;
  lastCount: number;
  createGlebeSuccess: Glebe | null;
}

export interface FirstForm {
  selectedGlebe: any;
  glebe: FormGroup;
}
export interface FormGroup {
  id: string;
  name: string;
  alias: string;
  cropId: string;
  cropName: string;
  varieties: string | null;
  sector: string;
  allotment: string | null;
  govCode: string;
  productiveUnit: string | null;
  program: string | null;
  harvested: boolean;
}
