<!-- Loading -->
<app-loading [isOpen]="state.loadingRemove" [excitedTime]="'1s'"></app-loading>

<div
  *ngIf="dataSource.data.length > 0"
  style="margin: 0px 15px"
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  <h2>{{ trans.title.trapsReports | translate }}</h2>
  <div>
    <button
      *ngIf="selection.hasValue() && applicationService.hasEditPermission(operation.remove)"
      type="button"
      mat-flat-button
      color="warn"
      (click)="archiveItems(selection.selected)"
      class="margin-right"
    >
      {{ trans.button.remove | translate }} ({{
        selection.selected.length >= 100 ? '99+' : selection.selected.length
      }}
      {{ trans.button.selected | translate }})
    </button>
    <button
      *ngIf="applicationService.hasEditPermission(operation.create)"
      type="button"
      mat-flat-button
      color="primary"
      (click)="openDialog(false, null)"
    >
      {{ trans.button.createReport | translate }}
    </button>
  </div>
</div>

<div [hidden]="dataSource.data.length <= 0" class="material-content">
  <mat-card class="material-top-card-border">
    <mat-card-header>
      <mat-card-title>{{ trans.title.listOfTrapReport | translate }}</mat-card-title>
      <mat-form-field class="margin-left-auto">
        <input
          #search
          matInput
          type="search"
          placeholder="{{ trans.placeholder.search | translate }}"
          (keyup)="dataSearch($event)"
        />
        <button
          mat-button
          matSuffix
          mat-icon-button
          aria-label="Search"
          (click)="dataSearch('', search)"
        >
          <mat-icon fontSet="material-icons-outlined">close</mat-icon>
        </button>
      </mat-form-field>
    </mat-card-header>
    <mat-card-content>
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        matSortDisableClear
        multiTemplateDataRows
        matSortActive="name"
        matSortDirection="desc"
      >
        <!-- Select -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef class="material-checkbox-th">
            <mat-checkbox
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [aria-label]="checkboxLabel()"
              [color]="'primary'"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)"
              [color]="'primary'"
              [disabled]="row.type === generateReportType.xls && row.status === 'IN_PROGRESS'"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- Items table -->
        <div *ngFor="let column of state.displayedColumns">
          <ng-container matColumnDef="{{ column }}" *ngIf="column === 'name'">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ trans.label[column] | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <a
                class="material-table-item-link"
                (click)="expandedElement = expandedElement === element ? null : element"
                >{{ element[column] }}</a
              >
            </td>
          </ng-container>
          <ng-container
            matColumnDef="{{ column }}"
            *ngIf="column !== 'select' && column !== 'actions' && column !== 'name'"
          >
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ trans.label[column] | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
          </ng-container>
        </div>
        <!-- View item -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="state.displayedColumns.length">
            <div
              class="material-element-detail"
              [@detailExpand]="expandedElement === element ? 'expanded' : 'collapsed'"
              [style.height]="expandedElement === element ? 'unset' : '0 !important'"
            >
              <div
                fxLayout="column"
                fxLayout.gt-xs="row wrap"
                fxLayout.gt-md="row wrap"
                class="reset-padding-15"
              >
                <div
                  fxFlex="100%"
                  fxFlex.gt-xs="0 1 50%"
                  fxFlex.gt-lg="0 1 16.65%"
                  *ngFor="let column of (state?.displayedForms)![element.reportFormat]"
                >
                  <div
                    *ngIf="
                      column !== 'glebeIds' &&
                      column !== 'fieldIds' &&
                      column !== 'occurrenceIds' &&
                      column !== 'type' &&
                      column !== 'infestationLevel'
                    "
                  >
                    <label> {{ trans.label[column] | translate }}:</label>
                    <p>{{ element[column] ? element[column] : '-' }}</p>
                  </div>
                </div>
                <div
                  fxFlex="100%"
                  *ngFor="let column of (state?.displayedForms)![element.reportFormat]"
                >
                  <div
                    class="material-list-view-custom"
                    *ngIf="
                      column === 'glebeIds' ||
                      column === 'fieldIds' ||
                      column === 'occurrenceIds' ||
                      column === 'type'
                    "
                  >
                    <ng-container *ngIf="element[column]?.length > 0; else elseTemplate">
                      <div *ngIf="!['glebeIds', 'fieldIds'].includes(column)">
                        <label> {{ trans.label[column] | translate }}:</label>
                        <p class="tm-background-primary" *ngFor="let item of element[column]">
                          {{ item }}
                        </p>
                      </div>
                      <div *ngIf="column === 'glebeIds'">
                        <label> {{ trans.label.glebeIds | label }}:</label>
                        <p class="tm-background-primary" *ngFor="let item of element[column]">
                          {{ item }}
                        </p>
                      </div>
                      <div *ngIf="column === 'fieldIds'">
                        <label> {{ trans.label.fieldIds | label }}:</label>
                        <p class="tm-background-primary" *ngFor="let item of element[column]">
                          {{ item }}
                        </p>
                      </div>
                    </ng-container>
                    <ng-template #elseTemplate>
                      <label>
                        {{ trans.label[column] | translate }}:
                        <span style="font-weight: normal">{{
                          trans.text.unselectedFilter | translate
                        }}</span>
                      </label>
                    </ng-template>
                  </div>
                  <div *ngIf="column === 'infestationLevel'">
                    <label> {{ trans.label[column] | translate }}:</label>
                    <p>
                      {{
                        element[column]
                          ? (trans.text[toLowerCase(element[column])] | translate)
                          : (trans.text.unselectedFilter | translate)
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </ng-container>
        <!-- Items actions -->
        <ng-container matColumnDef="actions" stickyEnd>
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="material-table-column-actions">
            <button
              *ngIf="element.type === generateReportType.pdf"
              mat-icon-button
              color="primary"
              matTooltipClass="material-actions-tooltip"
              [matTooltip]="trans.button.exportPdf | translate"
              (click)="openPdf(element.link)"
              [disabled]="!element.link"
            >
              <i class="las la-file-pdf"></i>
            </button>
            <button
              *ngIf="element.type === generateReportType.xls"
              mat-icon-button
              color="primary"
              matTooltipClass="material-actions-tooltip"
              [matTooltip]="trans.button.exportXls | translate"
              (click)="createReportXls(element.id)"
              [disabled]="element.data?.length <= 0 || element.status !== 'COMPLETED'"
            >
              <i class="las la-file-excel"></i>
            </button>
            <button
              *ngIf="applicationService.hasEditPermission(operation.update, true)"
              mat-icon-button
              color="primary"
              matTooltipClass="material-actions-tooltip"
              [matTooltip]="trans.button.edit | translate"
              (click)="openDialog(true, element)"
              [disabled]="element.status === 'COMPLETED' || element.type === generateReportType.xls"
            >
              <i class="las la-pencil-alt"></i>
            </button>
            <button
              *ngIf="applicationService.hasEditPermission(operation.remove, true)"
              mat-icon-button
              color="warn"
              matTooltipClass="material-actions-tooltip"
              [matTooltip]="trans.button.remove | translate"
              (click)="
                archiveItems([
                  { id: element.id, type: element.type, reportFormat: element.reportFormat }
                ])
              "
            >
              <i class="las la-trash"></i>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="state.displayedColumns; sticky: true"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: state.displayedColumns"
          class="material-row-height"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: ['expandedDetail']"
          class="material-detail-row"
        ></tr>
      </table>
    </mat-card-content>
    <mat-card-actions>
      <mat-paginator [pageSizeOptions]="state.pageSizeOptions" showFirstLastButtons>
      </mat-paginator>
    </mat-card-actions>
  </mat-card>
</div>

<app-empty-states-box
  *ngIf="!state.loading && dataSource.data.length <= 0"
  [iconSize]="'62px'"
  [messageSize]="'22px'"
  [messagePadding]="'45px 20px'"
  [icon]="'la-map-marked-alt'"
  [message]="trans.text.trapReportNotFound"
  [buttonOne]="trans.button.createReport"
  (buttonOneClick)="openDialog(false, null)"
></app-empty-states-box>
