import * as _ from 'lodash';
import { Action, createReducer, on } from '@ngrx/store';
import {
  TrapReportState,
  trapReportStateDefault,
} from 'src/app/view/trap-report/trap-reports.state';
import * as trapReportAction from './trap-report.actions';

export const trapReportReducer = createReducer(
  _.cloneDeep(trapReportStateDefault),

  /**
   * GET
   */
  on(trapReportAction.GET_TRAP_REPORTS, (state) => ({ ...state, loading: state.loading + 1 })),
  on(trapReportAction.GET_TRAP_REPORTS_SUCCESS, (state, action) => ({
    ...state,
    trapReports: action.trapReports,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(trapReportAction.GET_TRAP_REPORTS_FAIL, (state) => ({
    ...state,
    trapReports: [],
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  on(trapReportAction.GET_FIELDS, (state) => ({ ...state, loading: state.loading + 1 })),
  on(trapReportAction.GET_FIELDS_SUCCESS, (state, action) => ({
    ...state,
    fields: action.fields,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(trapReportAction.GET_FIELDS_FAIL, (state) => ({
    ...state,
    fields: [],
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  on(trapReportAction.GET_GLEBES, (state) => ({ ...state, loading: state.loading + 1 })),
  on(trapReportAction.GET_GLEBES_SUCCESS, (state, action) => ({
    ...state,
    glebes: action.glebes,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(trapReportAction.GET_GLEBES_FAIL, (state) => ({
    ...state,
    glebes: [],
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  on(trapReportAction.GET_TRAPS, (state) => ({ ...state, loading: state.loading + 1 })),
  on(trapReportAction.GET_TRAPS_SUCCESS, (state, action) => ({
    ...state,
    traps: action.traps,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(trapReportAction.GET_TRAPS_FAIL, (state) => ({
    ...state,
    fields: [],
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  /**
   * CREATE
   */
  on(trapReportAction.CREATE_REPORT_XLS, (state) => ({ ...state })),
  on(trapReportAction.CREATE_REPORT_XLS_SUCCESS, (state) => ({ ...state })),
  on(trapReportAction.CREATE_REPORT_XLS_FAIL, (state) => ({ ...state })),

  /**
   * REMOVE
   */
  on(trapReportAction.REMOVE_TRAP_REPORTS, (state) => ({
    ...state,
    loadingRemove: state.loadingRemove + 1,
  })),
  on(trapReportAction.REMOVE_TRAP_REPORTS_SUCCESS, (state) => ({
    ...state,
    loadingRemove: state.loadingRemove > 0 ? state.loadingRemove - 1 : (state.loadingRemove = 0),
  })),
  on(trapReportAction.REMOVE_TRAP_REPORTS_FAIL, (state) => ({
    ...state,
    loadingRemove: state.loadingRemove > 0 ? state.loadingRemove - 1 : (state.loadingRemove = 0),
  })),

  /**
   * OTHER
   */
  on(trapReportAction.DESTROY_COMPONENT, (state) => ({
    ..._.cloneDeep(trapReportStateDefault),
  }))
);

export function reducer(state: TrapReportState | undefined, action: Action): TrapReportState {
  return trapReportReducer(state, action);
}
