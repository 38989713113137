import { createAction, props } from '@ngrx/store';
import {
  Field,
  TrapReport,
  WebUser,
  Glebe,
  Trap,
} from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { StatusReportType } from '@tarvos-ag/tarvos-firestore-models/src/enums';

/**
 * GET
 */
// export const GET_TRAP_REPORTS = createAction('[Trap Report Form Page] GET_TRAP_REPORTS');
// export const GET_TRAP_REPORTS_SUCCESS = createAction(
//   '[Trap Report Form Page] GET_TRAP_REPORTS_SUCCESS',
//   props<{ trapReports: Array<TrapReport> }>()
// );
// export const GET_TRAP_REPORTS_FAIL = createAction(
//   '[Trap Report Form Page] GET_TRAP_REPORTS_FAIL',
//   props<{ error: any }>()
// );

export const GET_USERS = createAction('[Trap Report Form Page] GET_USERS');
export const GET_USERS_SUCCESS = createAction(
  '[Trap Report Form Page] GET_USERS_SUCCESS',
  props<{ users: Array<WebUser> }>()
);
export const GET_USERS_FAIL = createAction(
  '[Trap Report Form Page] GET_USERS_FAIL',
  props<{ error: any }>()
);

export const GET_HIGHEST_NUMBER_REGISTERED = createAction(
  '[Trap Report Form Page] GET_HIGHEST_NUMBER_REGISTERED'
);
export const GET_HIGHEST_NUMBER_REGISTERED_SUCCESS = createAction(
  '[Trap Report Form Page] GET_HIGHEST_NUMBER_REGISTERED_SUCCESS',
  props<{ lastCount: number }>()
);
export const GET_HIGHEST_NUMBER_REGISTERED_FAIL = createAction(
  '[Trap Report Form Page] GET_HIGHEST_NUMBER_REGISTERED_FAIL',
  props<{ error: any }>()
);

export const GET_FIELDS = createAction('[Trap Report Form Page] GET_FIELDS');
export const GET_FIELDS_SUCCESS = createAction(
  '[Trap Report Form Page] GET_FIELDS_SUCCESS',
  props<{ fields: Array<Field> }>()
);
export const GET_FIELDS_FAIL = createAction(
  '[Trap Report Form Page] GET_FIELDS_FAIL',
  props<{ error: any }>()
);

export const GET_GLEBES = createAction('[Trap Report Form Page] GET_GLEBES');
export const GET_GLEBES_SUCCESS = createAction(
  '[Trap Report Form Page] GET_GLEBES_SUCCESS',
  props<{ glebes: Array<Glebe> }>()
);
export const GET_GLEBES_FAIL = createAction(
  '[Trap Report Form Page] GET_GLEBES_FAIL',
  props<{ error: any }>()
);

export const GET_TRAPS = createAction('[Trap Report Form Page] GET_TRAPS');
export const GET_TRAPS_SUCCESS = createAction(
  '[Trap Report Form Page] GET_TRAPS_SUCCESS',
  props<{ traps: Array<Trap> }>()
);
export const GET_TRAPS_FAIL = createAction(
  '[Trap Report Form Page] GET_TRAPS_FAIL',
  props<{ error: any }>()
);

export const GET_OCCURRENCES = createAction('[Trap Report Form Page] GET_OCCURRENCES');
export const GET_OCCURRENCES_SUCCESS = createAction(
  '[Trap Report Form Page] GET_OCCURRENCES_SUCCESS',
  props<{ occurrences: Array<any> }>()
);
export const GET_OCCURRENCES_FAIL = createAction(
  '[Trap Report Form Page] GET_OCCURRENCES_FAIL',
  props<{ error: any }>()
);

/**
 * CREATE
 */
export const CREATE_TRAP_REPORT = createAction(
  '[Trap Report Form Page] CREATE_TRAP_REPORT',
  props<{ trapReport: TrapReport }>()
);
export const CREATE_TRAP_REPORT_SUCCESS = createAction(
  '[Trap Report Form Page] CREATE_TRAP_REPORT_SUCCESS',
  props<{ response: { status: StatusReportType; id: string } }>()
);
export const CREATE_TRAP_REPORT_FAIL = createAction(
  '[Trap Report Form Page] CREATE_TRAP_REPORT_FAIL',
  props<{ error: any }>()
);

export const CREATE_TRAP_REPORT_XLS = createAction(
  '[Trap Report Form Page] CREATE_TRAP_REPORT_XLS',
  props<{ id: string }>()
);
export const CREATE_TRAP_REPORT_XLS_SUCCESS = createAction(
  '[Trap Report Form Page] CREATE_TRAP_REPORT_XLS_SUCCESS'
);
export const CREATE_TRAP_REPORT_XLS_FAIL = createAction(
  '[Trap Report Form Page] CREATE_TRAP_REPORT_XLS_FAIL',
  props<{ error: any }>()
);

/**
 * UPDATE
 */
export const UPDATE_TRAP_REPORT = createAction(
  '[Modal Trap Report Page] UPDATE_TRAP_REPORT',
  props<{ trapReport: TrapReport }>()
);
export const UPDATE_TRAP_REPORT_SUCCESS = createAction(
  '[Modal Trap Report Page] UPDATE_TRAP_REPORT_SUCCESS'
);
export const UPDATE_TRAP_REPORT_FAIL = createAction(
  '[Modal Trap Report Page] UPDATE_TRAP_REPORT_FAIL',
  props<{ error: any }>()
);

/**
 * OTHERS
 */
export const HIDE_MODAL = createAction('[Trap Report Form Page] HIDE_MODAL');
