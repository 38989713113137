/**
 * NOTE - Comentários e Refatoração de código 19/05/2021
 */

import * as glebeFormAction from './glebe-form.actions';
import { Injectable } from '@angular/core';
import { CollectionReference } from '@angular/fire/compat/firestore';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Field, Glebe, Harvest } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { OccurrenceSettings } from 'src/app/interfaces/OccurrenceSettings';
import { ApplicationService } from '../../../services/application.service';
import { GenericFirestoreService } from '../../../services/generic-firestore.service';
import { GenericHttpService } from '../../../services/generic-http.service';
import { MyToastrService } from '../../../services/toastr.service';
import { TranslateTypes } from '../../../services/translation.service';
import {
  CreateGlebeForm,
  UpdateGlebeForm,
} from '@tarvos-ag/tarvos-firestore-models/src/httpFunctions/glebe';
import { LabelPipe } from 'src/app/pipes/label.pipe';
import { TranslateService } from '@ngx-translate/core';
import { CalculateService } from 'src/app/services/calculate.service';

@Injectable()
export class GlebeFormEffects {
  constructor(
    private actions$: Actions,
    private toastrService: MyToastrService,
    private genericFirestoreService: GenericFirestoreService,
    private genericHttpService: GenericHttpService,
    private applicationService: ApplicationService,
    private calculateService: CalculateService,
    private labelPipe: LabelPipe,
    public trans: TranslateTypes,
    private translateService: TranslateService
  ) {}

  /**
   * Este método chama um serviço para obter as configurações do sistema.
   */
  public getSystem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(glebeFormAction.GET_SYSTEM),
      switchMap(() =>
        this.genericFirestoreService
          .getAll<OccurrenceSettings>(
            `${this.applicationService.getHarvestRefDatabase()}/systemSettings`
          )
          .pipe(
            map((systems: Array<OccurrenceSettings>) =>
              glebeFormAction.GET_SYSTEM_SUCCESS({ system: systems[0] })
            ),
            catchError((error) => of(glebeFormAction.GET_SYSTEM_FAIL(error)))
          )
      )
    )
  );

  /**
   * Este método chama um serviço obter o maior número de registro
   */
  public getLastCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(glebeFormAction.GET_HIGHEST_NUMBER_REGISTERED),
      switchMap(() => {
        let lastCount;
        if (!this.applicationService.getFarm()?.collectionSettings?.glebe?.lastCount) {
          const combineList = this.applicationService.getHarvestList().map((harvest: Harvest) => {
            return {
              ref: `${this.applicationService.getFarmRefDatabase()}/harvests/${harvest.id}/glebes`,
              config: {
                queryFn: (ref: CollectionReference) =>
                  ref.orderBy('registrationNumber', 'desc').limit(1),
              },
            };
          });

          return this.genericFirestoreService.getCombine<Glebe>(combineList).pipe(
            map(
              (glebes: Array<Glebe>) => {
                const registrationNumberMap = glebes
                  .filter((glebe) => glebe.registrationNumber)
                  .map((glebe: any) => glebe.registrationNumber);
                lastCount = this.calculateService.calculateLastCount(registrationNumberMap);
                return glebeFormAction.GET_HIGHEST_NUMBER_REGISTERED_SUCCESS({
                  lastCount,
                });
              },
              catchError((error) => of(glebeFormAction.GET_HIGHEST_NUMBER_REGISTERED_FAIL(error)))
            )
          );
        } else {
          lastCount = this.applicationService.getFarm()?.collectionSettings?.glebe?.lastCount
            ? this.applicationService.getFarm()!.collectionSettings!.glebe!.lastCount
            : 0;
          return of(
            glebeFormAction.GET_HIGHEST_NUMBER_REGISTERED_SUCCESS({
              lastCount,
            })
          );
        }
      })
    )
  );

  /**
   * Este método chama um serviço para verificar se existe talhão para a gleba
   */
  public getHasFieldLinkedGlebe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(glebeFormAction.GET_HAS_FIELD_LINKED_GLEBE),
      switchMap((action) =>
        this.genericFirestoreService
          .getAll<Field>(`${this.applicationService.getHarvestRefDatabase()}/fields`, {
            queryFn: (ref: CollectionReference) => ref.where('glebe.id', '==', action.glebeId),
          })
          .pipe(
            map((field: Array<Field>) =>
              glebeFormAction.GET_HAS_FIELD_LINKED_GLEBE_SUCCESS({
                hasFieldLinkedGlebe: !!field.length,
              })
            ),
            catchError((error) => of(glebeFormAction.GET_HAS_FIELD_LINKED_GLEBE_FAIL(error)))
          )
      )
    )
  );

  /**
   * Este método chama um serviço para cadastrar gleba.
   */
  public createGlebe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(glebeFormAction.CREATE_GLEBE),
      mergeMap((action) => {
        return this.genericHttpService
          .create<CreateGlebeForm>('createGlebe', action.glebe)
          .toPromise()
          .then(() => {
            this.toastrService.success(
              this.labelPipe.transform(this.trans.services.create.glebeSuccess)
            );
            return glebeFormAction.CREATE_GLEBE_SUCCESS();
          })
          .catch((error) => {
            this.toastrService.error(
              this.labelPipe.transform(
                this.translateService.instant(
                  `${
                    error.code !== undefined
                      ? error.code
                      : error.error.code !== undefined
                      ? error.error.code
                      : error.error.internalCode
                  }`
                )
              )
            );
            return glebeFormAction.CREATE_GLEBE_FAIL(error);
          });
      })
    )
  );

  /**
   * Este método chama um serviço para atualizar glebas.
   */
  public updateGlebe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(glebeFormAction.UPDATE_GLEBE),
      mergeMap((action) => {
        return this.genericHttpService
          .update<UpdateGlebeForm>('updateGlebe', action.glebe)
          .toPromise()
          .then(() => {
            this.toastrService.success(
              this.labelPipe.transform(this.trans.services.update.glebeSuccess)
            );
            return glebeFormAction.UPDATE_GLEBE_SUCCESS();
          })
          .catch((error) => {
            this.toastrService.error(
              this.labelPipe.transform(this.translateService.instant(error.error.internalCode))
            );
            return glebeFormAction.UPDATE_GLEBE_FAIL(error);
          });
      })
    )
  );
}
