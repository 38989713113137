import * as _ from 'lodash';
import * as moment from 'moment';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ApplicationService } from 'src/app/services/application.service';
import { TranslateTypes } from 'src/app/services/translation.service';
import { CustomTableDirective } from '../utils/custom-table/custom-table';
import { Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DecimalPipe } from '@angular/common';
import { RemoveModalComponent } from '../components/remove-modal/remove-modal.component';
import { TrapReportState, trapReportStateDefault } from './trap-reports.state';
import {
  ARCHIVE_TRAP_REPORT,
  CREATE_REPORT_XLS,
  DESTROY_COMPONENT,
  GET_FIELDS,
  GET_GLEBES,
  GET_TRAPS,
  GET_TRAP_REPORTS,
  REMOVE_TRAP_REPORTS,
} from 'src/app/view/trap-report/trap-report.actions';
import { TrapReportFormComponent } from './trap-report-form/trap-report-form.component';
import {
  Field,
  TrapReport,
  Glebe,
  Occurrence,
  Trap,
} from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { Operation } from 'src/app/enums/Operation';
import { GenerateReportType, StatusReportType } from '@tarvos-ag/tarvos-firestore-models/src/enums';
import { OCCURRENCES } from '@tarvos-ag/tarvos-firestore-models/src/constants/occurrences';

@Component({
  selector: 'app-trap-report',
  templateUrl: './trap-report.component.html',
  styleUrls: ['./trap-report.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TrapReportComponent extends CustomTableDirective implements OnInit, OnDestroy {
  public trapReportState$: Observable<TrapReportState>;
  public subscribe!: Subscription;
  public subscribeDataSharing!: Subscription;
  public state: TrapReportState = trapReportStateDefault;
  public generateReportType = GenerateReportType;
  public operation = Operation;
  public occurrences = OCCURRENCES;

  constructor(
    private store: Store<any>,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private decimalPipe: DecimalPipe,

    public applicationService: ApplicationService,
    public trans: TranslateTypes
  ) {
    super();
    this.trapReportState$ = this.store.pipe(select('trapReportState'));
  }

  public ngOnInit(): void {
    this.subscribe = this.trapReportState$.subscribe((trapReportState: TrapReportState) => {
      this.state = trapReportState;
      if (this.state.trapReports) {
        this.setTableSetting();
      }
    });

    this.subscribeDataSharing = this.applicationService.updateComponentData.subscribe((key) => {
      if (key === TrapReportComponent.name) {
        this.store.dispatch(GET_TRAP_REPORTS());
        this.store.dispatch(GET_FIELDS());
        this.store.dispatch(GET_GLEBES());
        this.store.dispatch(GET_TRAPS());
      }
    });
    this.store.dispatch(GET_TRAP_REPORTS());
    this.store.dispatch(GET_FIELDS());
    this.store.dispatch(GET_GLEBES());
    this.store.dispatch(GET_TRAPS());
  }

  /**
   * Este método é executado quando o componente e destruído
   */
  public ngOnDestroy(): void {
    this.subscribe.unsubscribe();
    this.subscribeDataSharing.unsubscribe();
    this.store.dispatch(DESTROY_COMPONENT());
  }

  /**
   * Este método abre a modal para cadastrar ou editar
   * @param edit verifica se é uma edição
   * @param trapReport data
   */
  public openDialog(edit: boolean, trapReport: TrapReport | null): void {
    this.dialog.open(TrapReportFormComponent, {
      disableClose: true,
      panelClass: ['material-dialog-panel', 'material-dialog-panel-800'],
      data: {
        edit,
        trapReport: edit
          ? this.state.trapReports.filter((x: any) => trapReport && trapReport.id === x.id)[0]
          : null,
      },
    });
  }

  /**
   * Este método abre uma modal de confirmação para remover N itens
   * @param itemIds lista de itemIds
   */
  public removeItems(itemIds: Array<any>): void {
    const dialogRef = this.dialog.open(RemoveModalComponent, {
      disableClose: true,
      width: 'material-dialog-panel-500',
      data: {
        itemIds,
        title: itemIds.length > 1 ? this.trans.title.removeReports : this.trans.title.removeReport,
        message:
          itemIds.length > 1 ? this.trans.text.removeAllReport : this.trans.text.removeReport,
      },
    });
    const subs = dialogRef.afterClosed().subscribe((results) => {
      if (results) {
        const reports = results.map((result: any) => {
          return { id: result.id, type: result.type, reportFormat: result.reportFormat };
        });
        this.store.dispatch(REMOVE_TRAP_REPORTS({ reports }));
      }
      subs.unsubscribe();
    });
  }

  /**
   * Este método abre uma modal de confirmação para remover N itens
   * @param itemIds lista de itemIds
   */
  public archiveItems(itemIds: Array<any>): void {
    const dialogRef = this.dialog.open(RemoveModalComponent, {
      disableClose: true,
      width: 'material-dialog-panel-500',
      data: {
        itemIds,
        title: itemIds.length > 1 ? this.trans.title.removeReports : this.trans.title.removeReport,
        message:
          itemIds.length > 1 ? this.trans.text.removeAllReport : this.trans.text.removeReport,
      },
    });
    const subs = dialogRef.afterClosed().subscribe((results) => {
      if (results) {
        const reports = results.map((result: any) => {
          return { id: result.id, type: result.type, reportFormat: result.reportFormat };
        });
        this.store.dispatch(ARCHIVE_TRAP_REPORT({ reports }));
      }
      subs.unsubscribe();
    });
  }

  /**
   * Este método define as configurações iniciais da tabela
   * @param state Estado do componente
   */
  public setTableSetting(): void {
    const trapReports = _.cloneDeep(this.state.trapReports);
    this.dataSource = new MatTableDataSource<TrapReport>(
      trapReports.map((trapReport: any) => {
        if (trapReport.type === this.generateReportType.pdf) {
          if (trapReport.lastReportSent) {
            const endDate = moment(trapReport.lastReportSent.toDate())
              .subtract(1, 'days')
              .format('DD/MM/YYYY');
            const startDate = moment(trapReport.lastReportSent.toDate())
              .subtract(trapReport.period, 'days')
              .format('DD/MM/YYYY');

            trapReport.period = `${startDate} - ${endDate} (${
              trapReport.period
            } ${this.translateService.instant(this.trans.text.days)})`;
          } else {
            trapReport.period = trapReport.period
              ? `${trapReport.period} ${this.translateService.instant(this.trans.text.days)}`
              : null;
          }
        } else {
          trapReport.startDate = moment(trapReport.startDate.toDate()).format(
            this.translateService.instant(this.trans.text.date)
          );
          trapReport.endDate = moment(trapReport.endDate.toDate()).format(
            this.translateService.instant(this.trans.text.date)
          );
          trapReport.period = `${trapReport.startDate} - ${trapReport.endDate}`;
        }

        trapReport.statusName = trapReport.status
          ? this.translateService.instant(this.trans.enums[trapReport.status])
          : this.translateService.instant(this.trans.enums['IN_PROGRESS']);

        trapReport.format =
          trapReport.type === this.generateReportType.xls
            ? '-'
            : trapReport.reportFormat
            ? this.translateService.instant(this.trans.enums[trapReport.reportFormat])
            : null;

        if (trapReport?.fieldIds?.length > 0) {
          trapReport.fieldIds = trapReport.fieldIds.map((id: string) => {
            const value = this.state.fields.filter((field: Field) => field.id === id)[0];
            return `${value?.name} ${value?.alias ? `(${value?.alias})` : ''}`;
          });
        }

        if (trapReport?.glebeIds?.length > 0 || trapReport?.glebeId) {
          if (trapReport?.glebeIds?.length > 0) {
            trapReport.glebeIds = trapReport.glebeIds.map((id: string) => {
              const value = this.state.glebes.filter((glebe: Glebe) => glebe.id === id)[0];
              return `${value?.name} ${value?.alias ? `(${value?.alias})` : ''}`;
            });
          } else {
            const value = this.state.glebes.filter(
              (glebe: Glebe) => glebe.id === trapReport?.glebeId
            )[0];
            trapReport.glebeIds = [`${value?.name} ${value?.alias ? `(${value?.alias})` : ''}`];
          }
        }

        if (trapReport?.trapIds?.length > 0) {
          trapReport.trapIds = trapReport.trapIds.map((id: string) => {
            const value = this.state.traps.filter((trap: Trap) => trap.id === id)[0];
            return `${value?.name} ${value?.alias ? `(${value?.alias})` : ''}`;
          });
        }

        if (trapReport?.occurrenceIds?.length > 0) {
          trapReport.occurrenceIds = trapReport.occurrenceIds.map((id: string) => {
            const value = this.occurrences.filter(
              (occurrence: Occurrence) => occurrence.id === id
            )[0];
            return `${value?.name}`;
          });
        }

        trapReport.percentage = trapReport.percentage
          ? `${this.decimalPipe.transform(trapReport.percentage, '.2-2')}%`
          : '-';

        return trapReport;
      })
    );
    this.initTable(this.applicationService.filterColumnByPermission(this.state.displayedColumns));
  }

  /**
   * Este método baixa o pdf
   * @param url link
   */
  openPdf(url: string) {
    window.open(url, '_blank');
  }

  /**
   * Este método dispara uma ação para baixar o xls
   * @param id id do relatório de solicitação
   */
  public createReportXls(id: string): void {
    let trapReportXls: TrapReport = _.cloneDeep(
      this.state.trapReports.filter((trapReport: TrapReport) => trapReport.id === id)[0]
    );
    if (trapReportXls) {
      this.store.dispatch(CREATE_REPORT_XLS({ trapReportXls }));
    }
  }

  /**
   * @Override
   * Este método verifica Se o número de elementos selecionados corresponde ao número total de linhas.
   */
  public isAllSelected(): boolean {
    const numSelected = this.selection.selected.filter(
      (row: any) =>
        (row.type === this.generateReportType.xls && row.status !== StatusReportType.in_progress) ||
        row.type === this.generateReportType.pdf
    ).length;
    const numRows = this.dataSource.filteredData.filter(
      (row: any) =>
        (row.type === this.generateReportType.xls && row.status !== StatusReportType.in_progress) ||
        row.type === this.generateReportType.pdf
    ).length;
    return numSelected === numRows;
  }

  /**
   * @Override
   * Seleciona todas as linhas se elas não estiverem todas selecionadas; Ou seleciona linha por linha
   */
  public masterToggle(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.filteredData.forEach((row) => {
          if (
            (row.type === this.generateReportType.xls &&
              row.status !== StatusReportType.in_progress) ||
            row.type === this.generateReportType.pdf
          ) {
            this.selection.select(row);
          }
        });
  }

  public toLowerCase(level: string): string {
    return level.toLowerCase();
  }
}
