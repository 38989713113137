import {
  EvolutionChart,
  Field,
  MonitoringReport,
  TrapReport,
  Farm,
} from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { AccumulatedChart } from '@tarvos-ag/tarvos-firestore-models/src/interfaces/AccumulatedChart';
import { MadChart } from '@tarvos-ag/tarvos-firestore-models/src/interfaces/Mad';

export const reportStateDefault = {
  fields: [],
  loading: 0,
  report: null,
  startDate: '',
  endDate: '',
  customerId: '',
  farmId: '',
  farm: null,
  showModal: false,
  harvestId: '',
  monitoringReport: [],
  evolutionChart: [],
  evolutionChartByFields: null,
  reportAccumulated: null,
  madChart: [],
  selectedFieldId: '',
  updateEvolutionChartByFields: false,
  loadingEvolutionChartByFields: 0,
  loadingAccumulatedChart: 0,
  loadingMadChart: 0,
  phone: '',
} as ReportState;

export interface ReportState {
  fields: Array<Field>;
  customerId: string;
  farmId: string;
  farm: Farm | null;
  harvestId: string;
  report: TrapReport | null;
  showModal: boolean;
  startDate: string;
  endDate: string;
  monitoringReport: Array<MonitoringReport>;
  evolutionChart: Array<EvolutionChart>;
  madChart: Array<MadChart>;
  evolutionChartByFields: EvolutionChart | null;
  reportAccumulated: AccumulatedChart | null;
  updateEvolutionChartByFields: boolean;
  loading: number;
  loadingEvolutionChartByFields: number;
  loadingAccumulatedChart: number;
  loadingMadChart: number;
  selectedFieldId: string;
  phone: string;
}

export const cardFarm = [];
// export const cardFarm = ['country', 'state', 'city', 'district'];
export const cardGlebe = ['cropName', 'varieties'];
export const cardField = ['area', 'glebe', 'harvestDate'];
