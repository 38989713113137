import { Validators } from '@angular/forms';
import { OccurrenceSettings } from 'src/app/interfaces/OccurrenceSettings';
import { CustomValidators } from '../../utils/custom-validators/custom-validators';
import { Harvest } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

export const glebeFormStateDefault = {
  system: {} as OccurrenceSettings,
  harvests: [],
  hasFieldLinkedGlebe: false,
  loading: 0,
  loadingModal: 0,
  setPolygon: false,
  closeModal: false,
  lastCount: 0,
} as GlebeFormState;

export const formGroup = {
  value: [null, [Validators.maxLength(15)]],
  unit: [null],
};

export const firstFormGroup: FirstForm = {
  id: [null],
  name: [null, [Validators.required, Validators.minLength(2)]],
  alias: [
    null,
    [Validators.minLength(2), Validators.maxLength(30), CustomValidators.whitespaceValidator],
  ],
  cropId: [null, [Validators.required]],
  cropName: [null, [Validators.required]],
  varieties: [
    null,
    [Validators.minLength(2), Validators.maxLength(30), CustomValidators.whitespaceValidator],
  ],
  sector: [null, [CustomValidators.whitespaceValidator]],
  allotment: [null, [CustomValidators.whitespaceValidator]],
  govCode: [null, [CustomValidators.whitespaceValidator]],
  productiveUnit: [null, [CustomValidators.whitespaceValidator]],
  program: [null, [CustomValidators.whitespaceValidator]],
  harvested: [false],
} as any;

export interface GlebeFormState {
  system: OccurrenceSettings;
  harvests: Array<Harvest>;
  hasFieldLinkedGlebe: boolean;
  loading: number;
  loadingModal: number;
  closeModal: boolean;
  lastCount: number;
}

export interface FirstForm {
  id: string;
  name: string;
  alias: string;
  cropId: string;
  cropName: string;
  varieties: string | null;
  sector: string;
  allotment: string | null;
  govCode: string;
  productiveUnit: string | null;
  program: string | null;
  harvested: boolean;
}
