import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import {
  AreaType,
  BatteryLevel,
  FillFactorLevel,
  IconStatus,
  UnitOfMeasureOfOccurrence,
} from '@tarvos-ag/tarvos-firestore-models/src/enums';
import {
  EvolutionChart,
  Field,
  MonitoringReport,
  Occurrence,
  Glebe,
  Point,
} from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { Observable, Subscription } from 'rxjs';
import { borderColor } from 'src/app/constants/color';
import { IconUrl } from 'src/app/constants/mapIconCustom';
import { IconCustom, OccurrenceUrl } from 'src/app/constants/occurrenceIcon';
import { ApplicationService } from 'src/app/services/application.service';
import {
  // GET_ACCUMULATED_CHART,
  GET_EVOLUTION_CHART,
  // GET_MAD_CHART,
  GET_REPORT,
} from './report.actions';
import { ReportState, cardFarm, cardField, cardGlebe, reportStateDefault } from './report.state';
import { google } from 'google-maps';
import { TranslateTypes } from 'src/app/services/translation.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import moment from 'moment';
import { CardTrap, FieldsByGlebe, InfoCard } from 'src/app/interfaces/Report';
import _ from 'lodash';
import { PheromoneUrl, pheromoneIconCustom } from 'src/app/constants/pheromoneIcon';
import { cardFarmLabel, cardFieldLabel, cardGlebeLabel } from 'src/app/constants/cardLabel';
import { MatDialog } from '@angular/material/dialog';
import { InstructionModalComponent } from './instruction-modal/instruction-modal.component';
import { LabelPipe } from 'src/app/pipes/label.pipe';
import { TARVOS_WPP_LINK } from '@tarvos-ag/tarvos-firestore-models/src/constants/infos';
import { OCCURRENCES } from '@tarvos-ag/tarvos-firestore-models/src/constants/occurrences';

declare const google: google;

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit {
  public initMap: boolean = false;
  public mapTypeId: any = 'satellite';
  public borderColor: any = borderColor;
  public map: any = {};
  public selected: boolean = true;
  public groundOverlayList: Array<google.maps.GroundOverlay> = [];

  public formGroup!: FormGroup;

  public monitoringReportSelected!: MonitoringReport | null;
  public fieldSelected!: Field | null;
  public glebeSelected!: Glebe | null;
  public lastFieldsSelected!: Array<Field>;
  public occurrenceSelected!: Occurrence;
  public trapsField!: Array<any>;
  public expandView: boolean = false;
  public isMobile!: boolean;
  public moment = moment;
  public areaType = AreaType;
  public batteryLevel = BatteryLevel;
  public fillFactorLevel = FillFactorLevel;
  public unit = UnitOfMeasureOfOccurrence;
  public fieldsByGlebe!: Array<FieldsByGlebe>;
  public titleChart!: string;
  public titleFarm!: string;
  public titleInfo!: string;
  public cardFarm!: InfoCard | null;
  public cardTrap!: Array<CardTrap>;
  public generalTab!: boolean;
  public stateCaption: boolean = false;
  public noOccurrence: boolean = false;
  public fieldNoMonitoring!: boolean;
  public openTagPoint: boolean = true;

  public reportState$: Observable<ReportState>;
  public state: ReportState = reportStateDefault;
  public subscribe!: Subscription;

  public occurrences = OCCURRENCES;

  constructor(
    public applicationService: ApplicationService,
    public trans: TranslateTypes,
    private store: Store,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private labelPipe: LabelPipe,
    private dialog: MatDialog
  ) {
    this.reportState$ = this.store.pipe(select('reportState' as any));

    const token = this.route.snapshot.queryParams.token;
    const ux = this.route.snapshot.queryParams.ux;
    this.store.dispatch(GET_REPORT({ token, ux }));
    this.state.phone = TARVOS_WPP_LINK;
  }

  public ngOnInit(): void {
    this.onResize();
    this.formGroup = this.formBuilder.group({ occurrence: [null], field: [null] });
    this.initialTab();
    this.subscribe = this.reportState$.subscribe((state: ReportState) => {
      this.state = state;
      if (!this.initMap) {
        if (
          this.state.monitoringReport.length > 0 &&
          this.state.report &&
          this.occurrences.length > 0
        ) {
          this.titleFarm = this.labelPipe.transform(this.trans.label.farm);
          this.occurrenceSelected = this.checkTheOccurrencesMonitoring()[0];
          this.load_polygon_centroid();
          this.iconPoint();
          this.initMap = true;
        }
        if (this.state.fields.length > 0) {
          const fields = this.state.fields;
          this.lastFieldsSelected = fields;
          this.generalTab = true;
          this.updateReport();
          this.getGlebeAndFields(fields);
        }
        if (this.state.showModal) {
          if (this.state.showModal === true) this.modalInstruction();
        }
      }
    });
  }

  public async mapReady(map: any) {
    this.map = map;

    this.map.setOptions({
      tilt: 0,
      scaleControl: true,
    });
    this.onSelectionChangeOccurrence(this.checkTheOccurrencesMonitoring()[0]);
  }

  public async onSelectionChangeOccurrence(occurrence: Occurrence) {
    this.occurrenceSelected = occurrence;
    this.updateReport();

    await this.setGroundOverlay();
    await this.setFitBounds(this.lastFieldsSelected);

    const monitoringReport = occurrence
      ? this.getMonitoringReport().filter((report) => report.occurrenceId === occurrence.id)[0]
      : this.getMonitoringReport()[0];

    this.noOccurrence = monitoringReport === undefined || monitoringReport === null;
  }

  public async onSelectionChangeGlebes(fields: Array<Field>) {
    this.lastFieldsSelected = fields;
    this.fieldSelected = null;
    this.monitoringReportSelected = null;
    this.updateReport();
    await this.setGroundOverlay();
    await this.setFitBounds(fields);
  }

  public getMonitoringReport(): Array<any> {
    return this.state.monitoringReport
      .filter(
        (monitoringReport: MonitoringReport) =>
          monitoringReport.occurrenceId === this.occurrenceSelected.id
      )
      .filter((monitoringReport: MonitoringReport) => {
        return this.lastFieldsSelected.some((field) => field.id === monitoringReport.field.fieldId);
      });
  }

  public getPolygonPoints(fieldId: string): Array<Point> {
    const points: Array<Point> | undefined = this.state.fields.find(
      (field: Field) => field.id === fieldId
    )?.polygon.points;
    return points ? points : [];
  }

  public setGroundOverlay(fields?: Array<Field>): Promise<void> {
    this.groundOverlayList.forEach((groundOverlay) => groundOverlay.setMap(null));

    return new Promise<void>((resolve) => {
      if (!fields) {
        if (this.state.report && this.state.report.fieldIds) {
          for (const fieldId of this.state.report.fieldIds) {
            const points: Array<Point> = this.getPolygonPoints(fieldId);
            const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();

            points.forEach((coordinate) => {
              bounds.extend(new google.maps.LatLng(coordinate.lat, coordinate.lng));
            });

            const report = this.getMonitoringReport().filter(
              (report) => report.field.fieldId == fieldId
            )[0];

            if (report?.field?.heatmapImage) {
              const groundOverlay: google.maps.GroundOverlay = new google.maps.GroundOverlay(
                `data:image/svg;base64,${report.field.heatmapImage}`,
                bounds
              );
              groundOverlay.setOpacity(0.8);
              groundOverlay.setMap(this.map);
              this.groundOverlayList.push(groundOverlay);
            }
          }
        }
      }

      resolve();
    });
  }

  public setFitBounds(fields?: Array<Field>): Promise<void> {
    return new Promise((resolve) => {
      const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();

      if (!fields || fields.length == 0) {
        for (const report of this.getMonitoringReport()) {
          const points: Array<Point> = this.getPolygonPoints(report.field.fieldId);
          points.forEach((coordinate) => {
            bounds.extend(new google.maps.LatLng(coordinate.lat, coordinate.lng));
          });
        }
        this.map.fitBounds(bounds);
      } else {
        for (const field of fields) {
          const points: Array<Point> = field.polygon.points;
          points.forEach((coordinate) => {
            bounds.extend(new google.maps.LatLng(coordinate.lat, coordinate.lng));
          });
        }
        this.map.fitBounds(bounds);
      }
      resolve();
    });
  }

  public initialTab(): void {
    document.getElementById('defaultOpen')!.click();
  }

  public getIconPheromoneSvg(pheromone: string, fieldId?: string): any {
    const iconCustom = { ...pheromoneIconCustom };
    let focus = false;
    if (fieldId) {
      focus = this.monitoringReportSelected?.field.fieldId === fieldId;
    }

    if (pheromone) {
      if (!focus) {
        iconCustom.url = PheromoneUrl['NORMAL'][pheromone]
          ? `./../assets/icons/${PheromoneUrl['NORMAL'][pheromone]}`
          : `./../assets/icons/${PheromoneUrl['NORMAL']['Helicoverpa Armigera']}`;
      } else {
        iconCustom.url = PheromoneUrl['FOCO'][pheromone]
          ? `./../assets/icons/${PheromoneUrl['FOCO'][pheromone]}`
          : `./../assets/icons/${PheromoneUrl['FOCO']['Helicoverpa Armigera']}`;
      }
    } else {
      iconCustom.url = `./../assets/icons/${PheromoneUrl['Helicoverpa Armigera']}`;
    }
    return iconCustom;
  }

  public getIconOccurrence(occurrenceId: string): any {
    let url;
    if (occurrenceId) {
      url = OccurrenceUrl['OCCURRENCE'][occurrenceId]
        ? `./../assets/images/${OccurrenceUrl['OCCURRENCE'][occurrenceId]}`
        : `./../assets/images/${OccurrenceUrl['OCCURRENCE']['ARMIGERA']}`;
    } else {
      url = `./../assets/images/${IconUrl['TRAP'][IconStatus.LIGHT]}`;
    }
    return url;
  }

  public getIconOccurrenceSvg(occurrenceId: string): any {
    const iconCustom = IconCustom;

    if (occurrenceId) {
      iconCustom.url = OccurrenceUrl['OCCURRENCE'][occurrenceId]
        ? `./../assets/images/${OccurrenceUrl['OCCURRENCE'][occurrenceId]}`
        : `./../assets/images/${OccurrenceUrl['OCCURRENCE']['ARMIGERA']}`;
    } else {
      iconCustom.url = `./../assets/images/${IconUrl['TRAP'][IconStatus.LIGHT]}`;
    }
    return iconCustom;
  }

  public onFieldSelect(fieldId: string, glebeId: string): void {
    const monitoringReportFilter = this.getMonitoringReport().filter(
      (report) => report.field.fieldId == fieldId
    );
    if (monitoringReportFilter.length == 0) return;
    const monitoringReport = monitoringReportFilter[0];
    this.monitoringReportSelected = monitoringReport;
    if (monitoringReport) {
      this.initMap = true;
      this.monitoringReportSelected = monitoringReport;
      this.fieldSelected = this.state.fields.filter(
        (field: Field) => field.id === monitoringReport.field.fieldId
      )[0];
      // this.setAccumulatedChart(this.fieldSelected.id);
      // this.setMadChart(this.fieldSelected.glebe.name);
      this.iconPoint();
      if (monitoringReport.points.length > 0) {
        this.fieldNoMonitoring = false;
      } else {
        this.fieldNoMonitoring = true;
      }
      this.setCardTrap(monitoringReport.points);
      this.updateReport();
    } else {
      this.fieldSelected = null;
      this.noOccurrence = true;
    }

    let tablink = document.getElementById(glebeId);
    const tablinks = document.getElementsByClassName('tablinks');
    for (let i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' active', '');
    }
    tablink?.classList.toggle('active');
  }

  public getEvolutionChartData(): any {
    return this.state.evolutionChartByFields;
  }

  public getReportAccumulatedData(): any {
    return this.state.reportAccumulated;
  }

  public getOccurrences(): Array<Occurrence> {
    if (this.state.report && this.occurrences.length > 0) {
      return this.occurrences
        .filter(
          (occurrence: any) =>
            this.state.report &&
            this.state.report.occurrenceIds.filter((id: string) => id === occurrence.id).length >
              0 &&
            occurrence.id !== 'DANO_EM_ESTRUTURA_REPRODUTIVA'
        )
        .sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
    }

    return [];
  }

  public getGlebeAndFields(fields: Array<Field>): any {
    let glebes = {};
    if (fields.length > 0) {
      glebes = fields.reduce((acc: any, obj: Field) => {
        const key = `${obj.glebe.name}`;
        if (!acc[key]) {
          acc[key] = {
            glebe: obj.glebe,
            fields: [],
          };
        }
        if (acc[key]) {
          acc[key].fields.push(obj);
        }
        return acc;
      }, {});
    }
    this.fieldsByGlebe = [...Object.values(glebes)] as Array<FieldsByGlebe>;
  }

  public fieldName(fieldId: string): string | null {
    const field = this.state.fields.find((field: Field) => field.id === fieldId);

    // if (field) return field?.alias ? field.alias : field.name;
    if (field) return field.name;
    return null;
  }

  /**
   * Este método gera o título do gráfico
   * @param optionOne Objeto 1
   * @param optionTwo Objeto 2
   */
  public updateGeneralInfo(): void {
    if (!this.fieldSelected) {
      if (this.generalTab) {
        this.titleChart = this.occurrenceSelected
          ? ` (Geral) - ${this.occurrenceSelected.name}`
          : ` (Geral)`;
        this.setCardFarm();
      } else {
        this.setCardGlebe();
        this.titleChart = this.glebeSelected?.alias
          ? ` (${this.glebeSelected?.alias}) - ${this.occurrenceSelected.name}`
          : ` (${this.glebeSelected?.name}) - ${this.occurrenceSelected.name}`;
      }
    } else {
      this.setCardField();
      this.titleChart = !this.fieldNoMonitoring
        ? ` (${this.translateService.instant(this.trans.label['byTrap'])}) - ${
            this.occurrenceSelected.name
          }`
        : '';
    }
  }

  // private setAccumulatedChart(fieldId: string): void {
  //   const endDate = moment(this.state.endDate, 'DD-MM-YYYY').format('YYYY-MM-DD HH:mm:ss');
  //   this.store.dispatch(
  //     GET_ACCUMULATED_CHART({
  //       customerId: this.state.customerId,
  //       farmId: this.state.farmId,
  //       harvestId: this.state.harvestId,
  //       fieldId: fieldId,
  //       occurrenceId: this.occurrenceSelected.id,
  //       days: 10,
  //       endDate: new Date(endDate),
  //     })
  //   );
  // }

  // private setMadChart(glebeName: string): void {
  //   if (['CAPITATA', 'ANASTREPHA'].includes(this.occurrenceSelected.id)) {
  //     const { startDate, endDate } = this.convertPeriod(this.state.startDate, this.state.endDate);
  //     this.store.dispatch(
  //       GET_MAD_CHART({
  //         startDate: new Date(startDate),
  //         endDate: new Date(endDate),
  //         customerId: this.state.customerId,
  //         farmId: this.state.farmId,
  //         harvestId: this.state.harvestId,
  //         glebeName: glebeName,
  //       })
  //     );
  //   }
  // }

  public tabSelected(evt: any, glebe?: FieldsByGlebe): void {
    let i, tablinks, fields: Array<Field>;
    if (glebe) {
      fields = glebe.fields;
      this.glebeSelected = glebe.glebe;
      this.generalTab = false;
    } else {
      fields = this.state.fields;
      this.glebeSelected = null;
      this.generalTab = true;
    }
    this.clearCards();
    this.onSelectionChangeGlebes(fields);
    this.iconPoint();

    // Get all elements with class="tablinks" and remove the class "active"
    tablinks = document.getElementsByClassName('tablinks');
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' active', '');
    }

    // Show the current tab, and add an "active" class to the button that opened the tab
    evt.currentTarget.className += ' active';
  }

  public openCaption(evt: any): void {
    // Get all elements with class="tablinks" and remove the class "active"
    let tablinks = document.getElementsByClassName('content-caption')[0] as HTMLElement;
    if (!this.stateCaption) {
      evt.currentTarget.className += ' active';
      tablinks.style.display = 'block';
    } else {
      evt.currentTarget.className = 'collapsible';
      tablinks.style.display = 'none';
    }
    this.stateCaption = !this.stateCaption;
  }
  public changeView(): void {
    this.expandView = !this.expandView;
  }

  public updateReport(): void {
    this.updateGeneralInfo();
    if (this.fieldSelected) {
      const currentEvotutionChart = (
        !this.fieldNoMonitoring
          ? this.state.evolutionChart.find(
              (evolutionChart: EvolutionChart) =>
                evolutionChart.occurrenceId === this.occurrenceSelected.id &&
                this.monitoringReportSelected?.field.fieldId === evolutionChart.fieldId
            )
          : null
      ) as EvolutionChart | null;
      this.state.evolutionChartByFields = currentEvotutionChart;
    } else {
      if (this.lastFieldsSelected.length > 0 && this.occurrenceSelected) {
        const fieldIds = this.lastFieldsSelected.map((field: Field) => field.id);
        const occurrenceId = this.occurrenceSelected.id;
        const { startDate, endDate } = this.convertPeriod(this.state.startDate, this.state.endDate);
        this.store.dispatch(
          GET_EVOLUTION_CHART({
            startDate: new Date(startDate),
            endDate: new Date(endDate),
            customerId: this.state.customerId,
            farmId: this.state.farmId,
            harvestId: this.state.harvestId,
            fieldIds,
            occurrenceId,
          })
        );
      }
    }
  }

  public load_polygon_centroid(): void {
    this.state.monitoringReport = this.state.monitoringReport.map(
      (monitoringReport: MonitoringReport) => {
        if (monitoringReport.points.length > 0) {
          let points = this.getPolygonPoints(monitoringReport.field.fieldId);

          let first = points[0],
            last = points[points.length - 1];

          if (first.lng != last.lng || first.lat != last.lat) points.push(first);
          let area: number = 0,
            lng = 0,
            lat = 0,
            nPts = points.length,
            p1: { lat: number; lng: number },
            p2: { lng: number; lat: number },
            f: number;
          for (let i = 0, j = nPts - 1; i < nPts; j = i++) {
            p1 = points[i];
            p2 = points[j];
            f =
              (p1.lat - first.lat) * (p2.lng - first.lng) -
              (p2.lat - first.lat) * (p1.lng - first.lng);
            area += f;

            lng += (p1.lng + p2.lng - 2 * first.lng) * f;
            lat += (p1.lat + p2.lat - 2 * first.lat) * f;
          }
          f = area * 3;

          return {
            ...monitoringReport,
            centroid: { lat: lat / f + first.lat, lng: lng / f + first.lng },
          };
        }
        return {
          ...monitoringReport,
          centroid: null,
        };
      }
    );
  }

  public iconPoint(): void {
    this.state.monitoringReport = this.state.monitoringReport.map(
      (monitoringReport: MonitoringReport) => {
        let points = monitoringReport.points.map((point: any) => {
          const pheromone =
            point.iconType === 'NO_COMMUNICATION' ? point.iconType : point.pheromone;
          let icon = this.getIconPheromoneSvg(pheromone, point.fieldId);
          return { ...point, icon };
        });

        return { ...monitoringReport, points };
      }
    );
  }

  //seta os valores do card com informações de fazenda
  public setCardFarm(): void {
    if (this.state.farm) {
      let farm = [];
      for (const [key, value] of Object.entries(this.state.farm)) {
        if (cardFarm.some((item: string) => item == key) && value) {
          const label = cardFarmLabel[key];
          farm.push({ label: label, content: value });
        }
      }

      this.cardFarm = {
        title: `${this.translateService.instant(
          this.labelPipe.transform(this.trans.title['infoFarm'])
        )}`,
        subTitle: this.state.farm.name,
        field: farm,
        image: '../../../assets/images/Farm.png',
      };
    }
  }

  public onZoomChange(zoom: any): void {
    if (zoom >= 14) {
      this.openTagPoint = true;
    } else {
      this.openTagPoint = false;
    }
  }

  public setCardGlebe(): void {
    if (this.glebeSelected) {
      let glebe = [];
      for (let [key, value] of Object.entries(this.glebeSelected)) {
        if (cardGlebe.some((item: string) => item == key) && value) {
          const label = cardGlebeLabel[key];
          glebe.push({ label: label, content: value });
        }
      }

      this.cardFarm = {
        title: `${this.translateService.instant(
          this.labelPipe.transform(this.trans.title['infoGlebe'])
        )}`,
        subTitle: this.glebeSelected.alias ? this.glebeSelected.alias : this.glebeSelected.name,
        field: glebe,
        image: '../../../assets/images/Glebe.png',
      };
    }
  }

  public setCardField(): void {
    if (this.fieldSelected) {
      let fields = [];
      for (let [key, value] of Object.entries(this.fieldSelected)) {
        if (cardField.some((item: string) => item == key) && value) {
          const label = cardFieldLabel[key];
          if (key === 'glebe') {
            fields.push({ label: label, content: value.alias ? value.alias : value.name });
          } else if (key === 'area') {
            fields.push({ label: label, content: `${value.hectares}` });
          } else {
            fields.push({ label: label, content: value });
          }
        }
      }
      this.cardFarm = {
        title: `${this.translateService.instant(
          this.labelPipe.transform(this.trans.title['infoField'])
        )}`,
        // subTitle: this.fieldSelected.alias ? this.fieldSelected.alias : this.fieldSelected.name,
        subTitle: this.fieldSelected.name,
        field: fields,
        image: '../../../assets/images/Field.png',
      };
    }
  }
  public setCardTrap(points: Array<any>): void {
    if (points.length > 0) {
      this.cardTrap = [];
      points.forEach((point: any) => {
        this.cardTrap.push({
          title: `${this.translateService.instant(this.trans.label['trap'])}`,
          subTitle: point.alias ? point.alias : point.name,
          pheromoneText: this.translateService.instant(this.trans.text['pheromone']),
          pheromone: point.pheromone,
          iconText:
            point.iconType !== 'NO_COMMUNICATION'
              ? this.translateService.instant(this.trans.text['iconPheromone'], {
                  '%0': point.pheromone,
                })
              : this.translateService.instant(this.trans.text.trapNoCommunication),
          icon: point.icon.url,
          image: '../../../assets/icons/trap.svg',
        });
      });
    } else {
      this.cardTrap = [];
    }
  }

  public clearCards(): void {
    this.cardFarm = null;
    this.cardTrap = [];
  }
  @HostListener('window:resize', ['$event'])
  public onResize() {
    if (window.innerWidth < 1280) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  public checkTheOccurrencesMonitoring(): Array<Occurrence> {
    return this.getOccurrences().filter((occurrence: Occurrence) => {
      return this.state.monitoringReport.some(
        (report: MonitoringReport) => report.occurrenceId === occurrence.id
      );
    });
  }

  public openLinkWhats(): void {
    window.open(`${this.state.phone}`, '_blank');
  }

  public convertPeriod(startDate: string, endDate: string): { startDate: string; endDate: string } {
    const eDate = moment(endDate, 'DD-MM-YYYY');
    const period =
      eDate.diff(moment(startDate, 'DD-MM-YYYY'), 'days') <= 3
        ? 7
        : eDate.diff(moment(startDate, 'DD-MM-YYYY'), 'days');
    const endDateFormat = moment(endDate, 'DD-MM-YYYY')
      .subtract(1, 'days')
      .format('YYYY-MM-DD HH:mm:ss');
    const startDateFormat = moment(endDate, 'DD-MM-YYYY')
      .subtract(period, 'days')
      .format('YYYY-MM-DD HH:mm:ss');
    return { startDate: startDateFormat, endDate: endDateFormat };
  }

  public modalInstruction(): void {
    const isFruit = this.state.report?.occurrenceIds.some((occurrenceId) => {
      return ['CAPITATA', 'ANASTREPHA'].includes(occurrenceId);
    });

    const dialogRef = this.dialog.open(InstructionModalComponent, {
      panelClass: 'my-custom-dialog-class',
      height: isFruit ? '320' : '260',
      disableClose: false,
      data: { isFruit },
    });
    const subs = dialogRef.afterClosed().subscribe((results) => {
      // this.store.dispatch(REMOVE_TRAP({ ids }));
      subs.unsubscribe();
    });
  }

  createReportTitle(): string {
    if (!this.state.report) return '';

    if (this.state.report.alias) return ` - ${this.state.report.alias}`;
    else return ` (${this.state.report.name})`;
  }
}
