import { Action, createReducer, on } from '@ngrx/store';
import {
  DefineFieldFormState,
  defineFieldFormStateDefault,
} from 'src/app/view/import-kmz/define-field-form/define-field-form.state';
import * as defineFieldFormAction from './define-field-form.actions';
import * as _ from 'lodash';

export const defineFieldReducer = createReducer(
  _.cloneDeep(defineFieldFormStateDefault),

  /**
   * OBTER
   */
  on(defineFieldFormAction.GET_HIGHEST_NUMBER_REGISTERED, (state) => ({
    ...state,
    loading: state.loading + 1,
  })),
  on(defineFieldFormAction.GET_HIGHEST_NUMBER_REGISTERED_SUCCESS, (state, action) => ({
    ...state,
    lastCount: action.lastCount,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(defineFieldFormAction.GET_HIGHEST_NUMBER_REGISTERED_FAIL, (state) => ({
    ...state,
    lastCount: 0,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  on(defineFieldFormAction.GET_GLEBES, (state) => ({ ...state, loading: state.loading + 1 })),
  on(defineFieldFormAction.GET_GLEBES_SUCCESS, (state, action) => ({
    ...state,
    glebes: action.glebes,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(defineFieldFormAction.GET_GLEBES_FAIL, (state) => ({
    ...state,
    glebes: [],
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  /**
   * Cadastrar
   */
  on(defineFieldFormAction.CREATE_GLEBE, (state) => ({
    ...state,
    loadingModal: state.loadingModal + 1,
  })),
  on(defineFieldFormAction.CREATE_GLEBE_SUCCESS, (state, action) => ({
    ...state,
    closeModal: true,
    createGlebeSuccess: action.glebe,
  })),
  on(defineFieldFormAction.CREATE_GLEBE_FAIL, (state) => ({
    ...state,
    loadingModal: state.loadingModal > 0 ? state.loadingModal - 1 : (state.loadingModal = 0),
  })),

  /**
   * OUTROS
   */
  on(defineFieldFormAction.HIDE_MODAL, (state) => ({
    ..._.cloneDeep(defineFieldFormStateDefault),
  }))
);

export function reducer(
  state: DefineFieldFormState | undefined,
  action: Action
): DefineFieldFormState {
  return defineFieldReducer(state, action);
}
