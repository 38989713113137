import { Action, createReducer, on } from '@ngrx/store';
import { OccurrenceSettings } from 'src/app/interfaces/OccurrenceSettings';
import {
  OccurrenceSettingsState,
  occurrenceSettingsStateDefault,
} from 'src/app/view/configuration/occurrence-settings/occurrence-settings.state';
import * as occurrenceSettingsAction from './occurrence-settings.actions';
import * as _ from 'lodash';

export const OccurrenceSettingsReducer = createReducer(
  _.cloneDeep(occurrenceSettingsStateDefault),

  /**
   * GET
   */
  on(occurrenceSettingsAction.GET_OCCURRENCE_SETTINGS, (state) => ({
    ...state,
    loading: state.loading + 1,
  })),
  on(occurrenceSettingsAction.GET_OCCURRENCE_SETTINGS_SUCCESS, (state, action) => ({
    ...state,
    occurrenceSettings: action.occurrenceSettings,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(occurrenceSettingsAction.GET_OCCURRENCE_SETTINGS_FAIL, (state) => ({
    ...state,
    occurrenceSettings: {} as OccurrenceSettings,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  /**
   * UPDATE
   */
  on(occurrenceSettingsAction.UPDATE_OCCURRENCE_SETTINGS, (state) => ({
    ...state,
    loading: state.loading + 1,
  })),
  on(occurrenceSettingsAction.UPDATE_OCCURRENCE_SETTINGS_SUCCESS, (state) => ({
    ...state,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(occurrenceSettingsAction.UPDATE_OCCURRENCE_SETTINGS_FAIL, (state) => ({
    ...state,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  /**
   * OTHER
   */
  on(occurrenceSettingsAction.DESTROY_COMPONENT, (state) => ({
    ..._.cloneDeep(occurrenceSettingsStateDefault),
  }))
);

export function reducer(
  state: OccurrenceSettingsState | undefined,
  action: Action
): OccurrenceSettingsState {
  return OccurrenceSettingsReducer(state, action);
}
