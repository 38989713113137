import { createAction, props } from '@ngrx/store';
import { CreateGlebeForm } from '@tarvos-ag/tarvos-firestore-models/src/httpFunctions';
import { Glebe } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

/**
 * Obter
 */
export const GET_GLEBES = createAction('[Define Field Form Page] GET_GLEBES');
export const GET_GLEBES_SUCCESS = createAction(
  '[Define Field Form Page] GET_GLEBES_SUCCESS',
  props<{ glebes: Array<Glebe> }>()
);
export const GET_GLEBES_FAIL = createAction(
  '[Define Field Form Page] GET_GLEBES_FAIL',
  props<{ error: any }>()
);

export const GET_HIGHEST_NUMBER_REGISTERED = createAction(
  '[Define Field Form Page] GET_HIGHEST_NUMBER_REGISTERED'
);
export const GET_HIGHEST_NUMBER_REGISTERED_SUCCESS = createAction(
  '[Define Field Form Page] GET_HIGHEST_NUMBER_REGISTERED_SUCCESS',
  props<{ lastCount: number }>()
);
export const GET_HIGHEST_NUMBER_REGISTERED_FAIL = createAction(
  '[Define Field Form Page] GET_HIGHEST_NUMBER_REGISTERED_FAIL',
  props<{ error: any }>()
);

/**
 * Cadastrar
 */
export const CREATE_GLEBE = createAction(
  '[Define Field Form Page] CREATE_GLEBE',
  props<{ glebe: CreateGlebeForm }>()
);
export const CREATE_GLEBE_SUCCESS = createAction(
  '[Define Field Form Page] CREATE_GLEBE_SUCCESS',
  props<{ glebe: Glebe }>()
);
export const CREATE_GLEBE_FAIL = createAction(
  '[Define Field Form Page] CREATE_GLEBE_FAIL',
  props<{ error: any }>()
);

/**
 * Outros
 */
export const HIDE_MODAL = createAction('[Define Field Form Page] HIDE_MODAL');
