import * as _ from 'lodash';
import * as trapReportFormAction from './trap-report-form.actions';
import { Action, createReducer, on } from '@ngrx/store';
import {
  TrapReportFormState,
  trapReportFormStateDefault,
} from 'src/app/view/trap-report/trap-report-form/trap-report-form.state';

export const trapReportReducer = createReducer(
  _.cloneDeep(trapReportFormStateDefault),

  /**
   * GET
   */

  on(trapReportFormAction.GET_HIGHEST_NUMBER_REGISTERED, (state) => ({
    ...state,
    loading: state.loading + 1,
  })),
  on(trapReportFormAction.GET_HIGHEST_NUMBER_REGISTERED_SUCCESS, (state, action) => ({
    ...state,
    lastCount: action.lastCount,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(trapReportFormAction.GET_HIGHEST_NUMBER_REGISTERED_FAIL, (state) => ({
    ...state,
    lastCount: 0,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  // on(trapReportFormAction.GET_TRAP_REPORTS, (state) => ({ ...state, loading: state.loading + 1 })),
  // on(trapReportFormAction.GET_TRAP_REPORTS_SUCCESS, (state, action) => ({
  //   ...state,
  //   trapReports: action.trapReports,
  //   loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  // })),
  // on(trapReportFormAction.GET_TRAP_REPORTS_FAIL, (state) => ({
  //   ...state,
  //   trapReports: [],
  //   loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  // })),

  on(trapReportFormAction.GET_FIELDS, (state) => ({ ...state, loading: state.loading + 1 })),
  on(trapReportFormAction.GET_FIELDS_SUCCESS, (state, action) => ({
    ...state,
    fields: action.fields,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(trapReportFormAction.GET_FIELDS_FAIL, (state) => ({
    ...state,
    fields: [],
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  on(trapReportFormAction.GET_GLEBES, (state) => ({ ...state, loading: state.loading + 1 })),
  on(trapReportFormAction.GET_GLEBES_SUCCESS, (state, action) => ({
    ...state,
    glebes: action.glebes,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(trapReportFormAction.GET_GLEBES_FAIL, (state) => ({
    ...state,
    glebes: [],
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  on(trapReportFormAction.GET_TRAPS, (state) => ({ ...state, loading: state.loading + 1 })),
  on(trapReportFormAction.GET_TRAPS_SUCCESS, (state, action) => ({
    ...state,
    traps: action.traps,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(trapReportFormAction.GET_TRAPS_FAIL, (state) => ({
    ...state,
    traps: [],
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  on(trapReportFormAction.GET_USERS, (state) => ({ ...state, loading: state.loading + 1 })),
  on(trapReportFormAction.GET_USERS_SUCCESS, (state, action) => ({
    ...state,
    users: action.users.sort((a, b) => a.name.localeCompare(b.name)),
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(trapReportFormAction.GET_USERS_FAIL, (state) => ({
    ...state,
    users: [],
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

    on(trapReportFormAction.GET_OCCURRENCES, (state) => ({
      ...state,
      loading: state.loading + 1,
    })),
    on(trapReportFormAction.GET_OCCURRENCES_SUCCESS, (state, action) => ({
      ...state,
      occurrences: action.occurrences,
      loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
    })),
    on(trapReportFormAction.GET_OCCURRENCES_FAIL, (state) => ({
      ...state,
      occurrences: [],
      loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
    })),

  /**
   * CREATE
   */
  on(trapReportFormAction.CREATE_TRAP_REPORT, (state) => ({
    ...state,
    loadingModal: state.loadingModal + 1,
  })),
  on(trapReportFormAction.CREATE_TRAP_REPORT_SUCCESS, (state, action) => ({
    ...state,
    response: action.response,
    loadingModal: state.loadingModal > 0 ? state.loadingModal - 1 : (state.loadingModal = 0),
  })),
  on(trapReportFormAction.CREATE_TRAP_REPORT_FAIL, (state) => ({
    ...state,
    loadingModal: state.loadingModal > 0 ? state.loadingModal - 1 : (state.loadingModal = 0),
  })),

  on(trapReportFormAction.CREATE_TRAP_REPORT_XLS, (state) => ({
    ...state,
    loadingModal: state.loadingModal + 1,
  })),
  on(trapReportFormAction.CREATE_TRAP_REPORT_XLS_SUCCESS, (state) => ({
    ...state,
    closeModal: true,
  })),
  on(trapReportFormAction.CREATE_TRAP_REPORT_XLS_FAIL, (state) => ({
    ...state,
    loadingModal: state.loadingModal > 0 ? state.loadingModal - 1 : (state.loadingModal = 0),
  })),

  /**
   * UPDATE
   */
  on(trapReportFormAction.UPDATE_TRAP_REPORT, (state) => ({ ...state })),
  on(trapReportFormAction.UPDATE_TRAP_REPORT_SUCCESS, (state) => ({ ...state, closeModal: true })),
  on(trapReportFormAction.UPDATE_TRAP_REPORT_FAIL, (state) => ({ ...state })),

  /**
   * OTHER
   */
  on(trapReportFormAction.HIDE_MODAL, (state) => ({
    ..._.cloneDeep(trapReportFormStateDefault),
  }))
);

export function reducer(
  state: TrapReportFormState | undefined,
  action: Action
): TrapReportFormState {
  return trapReportReducer(state, action);
}
