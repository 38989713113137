import { GenericHttpService } from 'src/app/services/generic-http.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { ReportData } from '@tarvos-ag/tarvos-firestore-models/src/interfaces/ReportData';
import { of } from 'rxjs/internal/observable/of';
import { catchError, map, switchMap } from 'rxjs/operators';
import { TranslateTypes } from 'src/app/services/translation.service';

import moment from 'moment';

import * as reportAction from './report.actions';
import { MyToastrService } from 'src/app/services/toastr.service';
import { Router } from '@angular/router';
// import { GenericFirestoreService } from 'src/app/services/generic-firestore.service';
import { ApplicationService } from 'src/app/services/application.service';
// import { MadChart } from '@tarvos-ag/tarvos-firestore-models/src/interfaces/Mad';

@Injectable()
export class ReportEffects {
  constructor(
    private actions$: Actions,
    private translateService: TranslateService,
    private trans: TranslateTypes,
    private genericHttpService: GenericHttpService,
    // private genericFirestoreService: GenericFirestoreService,
    private applicationService: ApplicationService,
    private toastrService: MyToastrService,
    private router: Router
  ) {}

  /**
   * Este método chama um serviço para buscar o relatório.
   */
  public getReport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(reportAction.GET_REPORT),
      switchMap((action) => {
        const reportUrl = `getReport?token=${action.token}&ux=${action.ux}`;
        return this.genericHttpService.getById<any>(reportUrl).pipe(
          map((report: any) => {
            this.applicationService.setSystemSettings(report.systemSettings);
            return reportAction.GET_REPORT_SUCCESS({
              fields: report.fields,
              occurrences: report.occurrences,
              report: report.report,
              showModal: report.showModal,
              customerId: report.customerId,
              farmId: report.farmId,
              farm: report.farm,
              harvestId: report.harvestId,
              data: {
                monitoringReport: report.data.monitoring.monitoringReport,
                evolutionChart: report.data.evolution,
                startDate: moment(new Date(report.data.monitoring.startDate)).format(
                  this.translateService.instant(this.trans.text.date)
                ),
                endDate: moment(new Date(report.data.monitoring.endDate)).format(
                  this.translateService.instant(this.trans.text.date)
                ),
              } as ReportData,
            });
          }),
          catchError((error) => {
            this.toastrService.error(
              `${
                error.code !== undefined
                  ? error.code
                  : error.error.code !== undefined
                  ? error.error.code
                  : error.error.internalCode
              }`
            );
            if (error.error.code === 'backend.report.create.generic')
              this.router.navigate(['login']);
            return of(reportAction.GET_REPORT_FAIL(error));
          })
          // )
        );
      })
    );
  });

  /**
   * Este método chama um serviço para evolução dos levantamentos por talhões.
   */
  public getReportEvolutionChart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reportAction.GET_EVOLUTION_CHART),
      switchMap((action) =>
        this.genericHttpService
          .getAll<any>(
            `chart?type=reportEvolutionByFields&customerId=${action.customerId}&farmId=${action.farmId}&startDate=${action.startDate}&endDate=${action.endDate}&fieldIds=${action.fieldIds}&occurrenceId=${action.occurrenceId}&harvestId=${action.harvestId}`
          )
          .toPromise()
          .then((evolutionChart: any) => {
            return reportAction.GET_EVOLUTION_CHART_SUCCESS({ evolutionChart });
          })
          .catch((error) => {
            return reportAction.GET_EVOLUTION_CHART_FAIL(error);
          })
      )
    )
  );

  /**
   * Este método chama um serviço para evolução dos levantamentos por talhões.
   */
  // public getReportAccumulatedChart$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(reportAction.GET_ACCUMULATED_CHART),
  //     switchMap((action) =>
  //       this.genericHttpService
  //         .getAll<any>(
  //           `chart?type=accumulatedCounts&customerId=${action.customerId}&farmId=${action.farmId}&harvestId=${action.harvestId}&fieldId=${action.fieldId}&occurrenceId=${action.occurrenceId}&endDate=${action.endDate}&days=${action.days}`
  //         )
  //         .toPromise()
  //         .then((reportAccumulated: any) => {
  //           return reportAction.GET_ACCUMULATED_CHART_SUCCESS({ reportAccumulated });
  //         })
  //         .catch((error) => {
  //           return reportAction.GET_ACCUMULATED_CHART_FAIL(error);
  //         })
  //     )
  //   )
  // );

  /**
   * Este método chama um serviço para mad por gleba.
   */
  // public getReportMadChart$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(reportAction.GET_MAD_CHART),
  //     switchMap((action) =>
  //       this.genericHttpService
  //         .getAll<any>(
  //           `chart?type=mad&customerId=${action.customerId}&farmId=${action.farmId}&startDate=${action.startDate}&endDate=${action.endDate}&glebeName=${action.glebeName}&harvestId=${action.harvestId}`
  //         )
  //         .toPromise()
  //         .then((madChart: Array<MadChart>) => {
  //           return reportAction.GET_MAD_CHART_SUCCESS({ madChart });
  //         })
  //         .catch((error) => {
  //           return reportAction.GET_MAD_CHART_FAIL(error);
  //         })
  //     )
  //   )
  // );
}
