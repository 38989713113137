import { GmConfiguration } from 'src/app/interfaces/GmConfiguration';
import { FormControl, Validators } from '@angular/forms';
import { Timestamp } from 'src/app/interfaces/Types';
import moment from 'moment';
import { Field, Trap, Glebe } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

export const gmStateDefault = {
  traps: [],
  glebes: [],
  fields: [],
  reports: [],
  occurrences: [],
  gmConfig: {
    startDate: Timestamp.fromDate(moment().subtract(2, 'days').toDate()),
    endDate: Timestamp.fromDate(moment().subtract(1, 'days').toDate()),
    hideCollectionPoint: false,
    showFieldWithHighControlLevel: false,
  } as GmConfiguration,
  gmConfigEdit: {
    startDate: Timestamp.fromDate(moment().subtract(2, 'days').toDate()),
    endDate: Timestamp.fromDate(moment().subtract(1, 'days').toDate()),
    hideCollectionPoint: false,
    showFieldWithHighControlLevel: false,
  } as GmConfiguration,
  loading: 0,

  hasGmConfigInfo: false,
  shouldRenderMap: false,
} as GmState;

export interface GmState {
  traps: Array<Trap>;
  glebes: Array<Glebe>;
  fields: Array<Field>;
  reports: Array<any>;
  occurrences: Array<any>;
  gmConfig: GmConfiguration;
  gmConfigEdit: GmConfiguration;
  loading: number;
  hasGmConfigInfo: boolean;
  shouldRenderMap: boolean;
}

export const rangeDate = {
  startDate: new FormControl([null, [Validators.required]]),
  endDate: new FormControl([null, [Validators.required]]),
};
