import { createAction, props } from '@ngrx/store';
import { Glebe } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { GmConfiguration } from '../../interfaces/GmConfiguration';
import { Field, Trap } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

/**
 * Obter
 */
export const GET_FIELDS = createAction('[Gm Page] GET_FIELDS');
export const GET_FIELDS_SUCCESS = createAction(
  '[Gm Page] GET_FIELDS_SUCCESS',
  props<{ fields: Array<Field> }>()
);
export const GET_FIELDS_FAIL = createAction('[Gm Page] GET_FIELDS_FAIL', props<{ error: any }>());

export const GET_GLEBES = createAction('[Gm Page] GET_GLEBES');
export const GET_GLEBES_SUCCESS = createAction(
  '[Gm Page] GET_GLEBES_SUCCESS',
  props<{ glebes: Array<Glebe> }>()
);
export const GET_GLEBES_FAIL = createAction('[Gm Page] GET_GLEBES_FAIL', props<{ error: any }>());

export const GET_GM = createAction('[Gm Page] GET_GM');
export const GET_GM_SUCCESS = createAction(
  '[Gm Page] GET_GM_SUCCESS',
  props<{ gm: GmConfiguration }>()
);
export const GET_GM_FAIL = createAction('[Gm Page] GET_GM_FAIL', props<{ error: any }>());

export const GET_OCCURRENCES = createAction('[Gm Page] GET_OCCURRENCES');
export const GET_OCCURRENCES_SUCCESS = createAction(
  '[Gm Page] GET_OCCURRENCES_SUCCESS',
  props<{ occurrences: Array<any> }>()
);
export const GET_OCCURRENCES_FAIL = createAction(
  '[Gm Page] GET_OCCURRENCES_FAIL',
  props<{ error: any }>()
);

export const GET_TRAPS = createAction('[Trap Page] GET_TRAPS');
export const GET_TRAPS_SUCCESS = createAction(
  '[Trap Page] GET_TRAPS_SUCCESS',
  props<{ traps: Array<Trap> }>()
);
export const GET_TRAPS_FAIL = createAction('[Trap Page] GET_TRAPS_FAIL', props<{ error: any }>());

/**
 * Atualizar
 */
export const UPDATE_GM_EDIT = createAction(
  '[Gm Page] UPDATE_GM_EDIT',
  props<{ gm: GmConfiguration }>()
);
export const UPDATE_GM = createAction('[Gm Page] UPDATE_GM', props<{ gm: GmConfiguration }>());
export const UPDATE_GM_SUCCESS = createAction('[Gm Page] UPDATE_GM_SUCCESS');
export const UPDATE_GM_FAIL = createAction('[Gm Page] UPDATE_GM_FAIL', props<{ error: any }>());
